import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';

export default function DeletePlacedProductConf({ open, setOpen }) {
    const handleClose = () => setOpen(false)

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack alignItems='center'>
                            <Typography variant='h5' fontWeight={600}>Are you sure you want to delete this item ?</Typography>
                            <Typography>Tile: Endura 1200 x 200 ADOKS FIREWOOD</Typography>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='center'>
                            <Button variant='outlined'>No</Button>
                            <Button variant='contained'>Yes</Button>
                        </Stack>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

