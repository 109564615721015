import React from 'react'
import Appbar from '../../components/Appbar'
import Drawer from '../../components/Drawer'
import Body from '../../components/Body'
import { Box, Paper, Stack, Typography } from '@mui/material'
import InventoryIcon from '@mui/icons-material/Inventory';
import { DataGrid } from '@mui/x-data-grid'


export default function BillsAndInvoices() {

    const columns = [
        {
            field: 'order_id',
            headerName: 'Purchase Order No.',
            width: 250
        },
        {
            field: 'supplier',
            headerName: 'PO Date',
            width: 250
        },
        {
            field: 'product',
            headerName: 'Invoice Date',
            width: 250
        },
        {
            field: 'order_date',
            headerName: 'Download',
            width: 400
        },
    ]

    return (
        <>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>Welcome Amit Kia Noida</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>View Bills and Invoices</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid
                        rows={[]}
                        columns={columns}
                        disableRowSelectionOnClick
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
            </Body>
        </>
    )
}
