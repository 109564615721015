import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, FormControl, Grid, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { inputDrawingApi } from '../../store/home/layoutTable';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom';
import FilterStyles from "../../styles/filters.module.scss";
import meter from "../../assets/img/meter.png";
import LayoutImg from '../../assets/img/all_layout.png'
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { showToast } from '../../toast/toast';
import { resetSchematicLayoutByClusterState } from '../../store/home/getSchematicLayout';
import { reseGFC } from '../../store/home/getGFC';
import { resetRevisedLayout } from '../../store/home/getRevisedLayout';
import { resetRevisedGFC } from '../../store/home/getRevisedGFC';
import { FilterDramaTwoTone } from '@mui/icons-material';
import { resetRevisedViewGFC } from '../../store/home/postRevisedGFCView';
import { resetDesignEstimateState } from '../../store/home/getDesignEstimate';
import AddInventory from '../reuseable/AddInventoryModal';

export default function LayoutTable() {
    localStorage.removeItem("selectedOutletIdToView");
    localStorage.removeItem("isRevisedViewClick");
    localStorage.removeItem("isRevisedGFCViewClick");
    const [applicableDaysAnchorEl, setApplicableDaysAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading : inputDrawingLoading} = useSelector((state) => state?.inputDrawingReducer);

    const [inputDrawing, setInputDrawing] = useState([]);
    const [allInputDrawing, setAllInputDrawing] = useState([]);
    const [isActive, setIsActive] = useState('recived_from_dealor');
    const [searchTermDealer, setSearchTermDealer] = useState("");
    const [searchTermLocation, setSearchTermLocation] = useState("");


    const [location, setLocation] = useState("");

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleNavigate = (outletId) => {
        localStorage.setItem("selectedOutletIdToView", outletId);
        navigate(`/layout/view`);
    };

    const handleApplicableDaysMenuClick = (event) => {
        setApplicableDaysAnchorEl(event.currentTarget);
    };

    const handleApplicableDaysMenuClose = () => {
        setApplicableDaysAnchorEl(null);
    };

    const getAllInputDrawing = async() => {
        try {
            const response = await dispatch(inputDrawingApi());
            const data = response?.payload?.data || [];
            setAllInputDrawing(data);
            setInputDrawing(data.filter(feedback => feedback?.recived_from_dealor === true));
        } catch (error) {
            showToast(error, 2)
        }
    }

    useEffect(() => {
        getAllInputDrawing()
        dispatch(resetSchematicLayoutByClusterState())
        dispatch(reseGFC())
        dispatch(resetRevisedLayout());
        dispatch(resetRevisedGFC())
        dispatch(resetRevisedViewGFC())
        dispatch(resetDesignEstimateState())
    }, []);


    useEffect(() => {
        const filteredData = allInputDrawing.filter(row =>
            row.dealor_outlet_name?.toLowerCase().includes(searchTermDealer?.toLowerCase()) ||
            row.location?.toLowerCase().includes(searchTermLocation?.toLowerCase())
        );
        setInputDrawing(filteredData);
    }, [searchTermDealer, searchTermLocation]);
    
    return (
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                    <img src={LayoutImg} alt="Meter" />
                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        All Layout
                    </Typography>
                </Stack>
                <Stack direction="row" flex={1} justifyContent="end" gap={2}>
                    <Box flexGrow={1} maxWidth={180}>
                        <Stack direction="row">
                            <Typography className={FilterStyles.filtersLabel} variant="label">
                                By Dealer
                            </Typography>
                        </Stack>
                        <TextField
                            className="filterField2"
                            id="standard-basic"
                            placeholder="Dealer"
                            variant="standard"
                            inputProps={{ style: { fontSize: 13 } }}
                            onChange={(e) => setSearchTermDealer(e.target.value)}
                        />
                    </Box>
                    <Box flexGrow={1} maxWidth={180}>
                        <Stack direction="row">
                            <Typography className={FilterStyles.filtersLabel} variant="label">By Location</Typography>
                        </Stack>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                            <TextField
                                className="filterField2"
                                id="standard-basic"
                                placeholder="Location"
                                variant="standard"
                                inputProps={{ style: { fontSize: 13 } }}
                                onChange={(e) => setSearchTermLocation(e.target.value)}
                            />
                        </FormControl>
                        <Menu
                            anchorEl={applicableDaysAnchorEl}
                            open={Boolean(applicableDaysAnchorEl)}
                            onClose={handleApplicableDaysMenuClose}
                        >
                            <MenuItem>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker />
                                    <DatePicker />
                                </LocalizationProvider> */}
                            </MenuItem>
                        </Menu>
                    </Box>
                </Stack>
            </Stack>

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ background: "#85949F !important" }}>Received Input Drawing</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} >Input Drawing Received Date</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} >Schematic Layout Status</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} >GFC Status</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} >Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {inputDrawingLoading ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={15}>
                                            <CircularProgress color="primary" size={10} thickness={5} style={{ padding: "10px" }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    !inputDrawingLoading && inputDrawing ? (
                                        inputDrawing.filter(row => row.approve_by_admin).length > 0 ? (
                                            inputDrawing.filter(row => row.approve_by_admin).map((row) => (
                                                row.recived_from_dealor && (
                                                    <StyledTableRow key={row.dealor_outlet_id}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {row.dealor_outlet_trade_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {row.schematic_status === false ? (
                                                                <Button className="notdoneBtn">Not Sent</Button>
                                                            ) : (
                                                                <Button className="doneBtn">Sent</Button>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {row.gfc_status === true ? (
                                                                <Button className="doneBtn">Sent</Button>
                                                            ) : (
                                                                <Button className="notdoneBtn">Not Sent</Button>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Button
                                                                className="actionBtn"
                                                                variant="contained"
                                                                onClick={() => handleNavigate(row.dealor_outlet_id)}
                                                                sx={{textTransform: 'none'}}
                                                            >
                                                                View
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            ))
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={9} align="center">
                                                    No Data Found!
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    ) : (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">
                                        No Data Found!
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </Body>
        
    );
}
