import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { getRevisedLayoutApi } from '../../store/home/getRevisedLayout';
import { convertToFormData } from '../convertFormData';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { handleDownload, handleDownloadMultipleFile } from '../reuseable/downloadFile';
import { postRevisedLayoutView, resetRevisedViewLayout } from '../../store/home/postRevisedLayoutView';
import { showToast } from '../../toast/toast';
import Loader from '../reuseable/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function RevisedLayout({ formID, setSelectedTab }) {
    localStorage.removeItem("isRevisedViewClick");
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { result: revisedLayoutList, loading: layoutLoading } = useSelector((state) => state?.revisedLayout);

    const getRevisedLayout = async () => {
        const formD = { form_id: formID };
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(getRevisedLayoutApi(formData));
            setData(response?.payload?.data);
        } catch (error) {
            showToast(error, 2);
        }
    };

    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedViewClick", 1);
            await dispatch(postRevisedLayoutView(data));
            setSelectedTab(3);
        } catch (error) {
            showToast(error, 2);
        }
    };

    

    useEffect(() => {
        getRevisedLayout();
    }, [formID]);

    useEffect(() => {
        dispatch(resetRevisedViewLayout());
    }, [dispatch]);

    if (layoutLoading) {
        return <Loader/>;
    }
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" className="fs-17 fw-400">Schematic Revisions</Typography>
                </Grid>
                
                        {revisedLayoutList?.length ? (
                            <>
                                <Grid item xs={12}>
                                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                                        <Button className='layout-blue-btn'>Feedback from KIA</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Schematic Sent Date</StyledTableCell>
                                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {revisedLayoutList.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {convertToDateFormatYearWithTwoDigits(row?.created_at)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Button className="DownloadBtn" sx={{ marginRight: 1 }} onClick={() => handleView(row)}>
                                                                <RemoveRedEyeRoundedIcon fontSize='small' />View
                                                            </Button>
                                                            <Button className="DownloadBtn" sx={{ marginRight: 1 }} onClick={() => handleDownloadMultipleFile(new Date() + 'schematic_cad', row?.schematic_cad)}>
                                                                <DownloadForOfflineRoundedIcon fontSize='small' />CAD
                                                            </Button>
                                                            <Button className="DownloadBtn" onClick={() => handleDownloadMultipleFile(new Date() + 'schematic_pdf', row?.schematic_pdf)}>
                                                                <DownloadForOfflineRoundedIcon fontSize='small' />PDF
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell sx={{ background: "#85949F !important" }}>Schematic Sent Date</StyledTableCell>
                                                <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={2} align="center">No data</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                   
            </Grid>
        </Box>
    );
}
