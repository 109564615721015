import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const sendFeedback = createAsyncThunk(
    'layout/sendToKin',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/layout/add_feedback_on_input_drawing/`, data,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const sendFeedbackSlice = createSlice({
    name: 'sendToKin',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(sendFeedback.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(sendFeedback.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(sendFeedback.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const sendFeedbackReducer = sendFeedbackSlice.reducer