import React from "react"
import Body from '../../components/Body'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import meter from '../../assets/img/meter.png'
import { Link } from "react-router-dom";
import floormap from "../../assets/svg/floormap.svg";
import { styled } from '@mui/material/styles';
import tiles from "../../assets/img/tiles.png";
import tiles2 from "../../assets/img/tiles2.png";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
function OrderValidationHistory() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    return (
        <Body>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className='d-flex' sx={{paddingBlock:"0px !important",background:"none !important",boxShadow:"none !important"}}>
                        <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                            <img style={{marginBottom:"5px !important"}} src={meter} alt="meterIcon"/>
                            <Typography sx={{lineHeight:"normal !important"}} >Order Validation
                            History</Typography>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card className="order-card">
                        <CardHeader
                            avatar={
                                <Box sx={{ borderRight: "1px solid #D4E0EC !important",paddingRight: "10px"}}>
                                    <Typography variant="h5" className="fs-15 fw-400">Amit KIA</Typography>
                                    <Typography varient="h5" className="fs-15 fw-400">Noida</Typography>
                                </Box>
                            }
                            action={
                                <Box>
                                    <Button className="white-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Packed
                                    </Button>
                                    <Link className="" style={{margin:"auto 23px auto 23px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Update
                                    </Link>
                                    <Button className="black-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Download
                                    </Button>
                                </Box>
                            }
                            title="Main Order ID"
                            subheader="AKN23415"
                        />
                        <CardContent>
                            <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow:"none !important" }}>
                                <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    {/* <TableHead>
                                        <TableRow>
                                      
                                            <TableCell>
                                            thead
                                            </TableCell>
                                      
                                        </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                              
                                            <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Order Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    1st September 2023
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Order Total
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    ₹ 1,79,000 (2 Materials)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Order Finalized Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    6th September 2023
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Payment on
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    5th September2023
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Transportation Number
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Expected Delivery Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Delivery Contact Number
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Delivery Contact Name
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                            </TableRow>
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Button className="white-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                            <img src={floormap} /> View GFC
                            </Button>
                            <Button className="fs-12 btn-no-property" sx={{marginLeft:"auto",textTransform:"capitalize",background:"none !important",color:"#000000 !important"}}     
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}>
                                Show more
                            <ExpandMore
                            className="fs-12"
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                            <ExpandLessIcon className="" sx={{color:"#000000"}} />
                            </ExpandMore>
                            </Button>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Grid container sx={{borderBottom:"3px solid #E6F1FC",paddingBlock:"15px !important"}}>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <Box>
                                            <img style={{width:"90%"}} src={tiles} />
                                            <Box className="d-flex">
                                                <Typography variant="h5" className="fs-14">Order ID: &nbsp;</Typography>
                                                <Typography variant="h5" className="fs-14 fw-600" sx={{color:"#157DC9 !important"}}>SD1234</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <Box className="d-flex w-100">
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Order Status
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        -
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        Manufacturer/Vendor
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50">
                                                        Tiles  |  HR Johnson
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Qty
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        2000 sq.ft/5000
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        ETA
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border">
                                                        -
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{borderBottom:"3px solid #E6F1FC",paddingBlock:"15px !important"}}>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <Box>
                                            <img style={{width:"90%"}} src={tiles2} />
                                            <Box className="d-flex">
                                                <Typography variant="h5" className="fs-14">Order ID: &nbsp;</Typography>
                                                <Typography variant="h5" className="fs-14 fw-600" sx={{color:"#157DC9 !important"}}>SD1234</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <Box className="d-flex w-100">
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Order Status
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        -
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        Manufacturer/Vendor
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50">
                                                        Tiles  |  HR Johnson
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Qty
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        2000 sq.ft/5000
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        ETA
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border">
                                                        -
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                        </Card>
                    </Grid>
            </Grid>
        </Body>
    )  
}

export default OrderValidationHistory