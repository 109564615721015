
import { Box, Button, Card, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import Style from "../../styles/home.module.scss";
import Body from '../../components/Body'
import meter from '../../assets/img/meter.png'

import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import QuickAction from './QuickAction';
import DashboardNotificationBlock from './DashboardNotificationBlock';
import DashboardLayoutMangementBlock from './DashboardLayoutMangementBlock';
import DashboardOrderBlock from './DashboardOrderBlock';
import DashboardCategoryBlock from './DashboardCategoryBlock';
import DashboardInventoryBlock from './DashboardInventoryBlock';
import DashboardGfcStatusBlock from './DashboardGfcStatusBlock';
export default function Home() {
  const navigate = useNavigate()
  return (
    <>
      <Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
            <Card className='d-flex' sx={{paddingBlock:"0px !important",background:"none !important",boxShadow:"none !important"}}>
              <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                <img style={{marginBottom:"5px !important"}} src={meter} alt="meterIcon"/>
                <Typography sx={{lineHeight:"normal !important"}} >Dashboard</Typography>
              </Stack>
              {/* <Box className="search" sx={{marginLeft:"auto"}}>
                <TextField
                  fullWidth
                 
                  variant="outlined"
                  placeholder="Search by Location , Name"
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box> */}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>  
            <Grid container spacing={2}>
              {/* <DashboardCategoryBlock/> */}
              <DashboardLayoutMangementBlock/>
              {/* <DashboardNotificationBlock/> */}
              <DashboardOrderBlock/>
              <DashboardInventoryBlock/>
              {/* <DashboardGfcStatusBlock/> */}
            </Grid>
          </Grid>
          <QuickAction/>
        </Grid>
      </Body>
    </>
  )
}
