import React, { useEffect, useState } from "react"
import Body from '../../components/Body'
import { Box, Button, Card, CardActions, CardContent, CardHeader,  FormHelperText,  Grid, IconButton, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow,  TextField,  Typography } from "@mui/material"
import meter from '../../assets/img/meter.png'
import { styled } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { ordersApi } from "../../store/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import { convertDateFunctionYMD, formatDateString } from "../../utils/dateFormater";
import UploadOrderInvoiceModal from "../reuseable/UploadOrderInvoiceModal";
import { handleDownload } from "../reuseable/downloadFile";
import ViewPaymentModal from "../reuseable/ViewPaymentModal";
import ViewUploadedQuotationModal from "../reuseable/ViewUploadedQuotationModal";
import ViewUploadedOrderInvoiceModal from "../reuseable/ViewUploadedOrderInvoiceModal";
import { calculateTotal } from "../../utils/priceManipulation";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { updateOrderStatusSchema } from "../validationSchemas";
import { showToast } from "../../toast/toast";


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
function OrderDispatchStatus() {
    const dispatch = useDispatch();
    const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders); 

    const [currentItem, setCurrentItem] = useState([])
    const [updateStatusRequest, setUpdateStatusRequest] = useState()
    const [errors, setErrors] = useState({});
    const [stage, setStage] = useState(1)
    const [dispatch_date, setDispatchDate] = useState(null);
    
    const handleUpdateStatus = (num, item) => {
        setStage(num)
        setCurrentItem(item)
        setUpdateStatusRequest()
        setErrors({})
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(value === "") {
            setUpdateStatusRequest((preValue) => {
                return {
                    ...preValue,    
                    [name]: ''
                }
            })
        }else{
            setUpdateStatusRequest((preValue) => {
                return {
                    ...preValue,    
                    [name]: value
                }
            })
        }
        updateError(name);
    
    };
    const updateError = (name)=>{
        setErrors((preValue) => {
            return {
                ...preValue,
                [name] : ""
            }
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const mergedErrors = { ...errors };
        updateOrderStatusSchema.validate(updateStatusRequest, { abortEarly: false })
            .then(() => {
                console.log(updateStatusRequest)
                // if (localStorage?.getItem("selectedId") && localStorage.getItem("selectedId") !== undefined && localStorage.getItem("selectedId") !== "") {
                //     editRequestAPI()
                // } else {
                //     addRequestAPI();
                // }
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors({ ...mergedErrors, ...newErrors });
                //showToast("Please fill mandatory fields", 2)
            });
    };

    useEffect(() => {
        if (dispatch_date !== null) {
            setUpdateStatusRequest((preValue) => {
                return {
                    ...preValue,
                    'dispatch_date': convertDateFunctionYMD(dispatch_date)
                }
            })
        }
        updateError("dispatch_date");

    }, [dispatch_date])

    useEffect(() => {
        dispatch(ordersApi())
    },[dispatch])
    console.log(errors, "errors");
    return (
        
        <Body>
        <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className='d-flex' sx={{paddingBlock:"0px !important",background:"none !important",boxShadow:"none !important"}}>
                        <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                            <img style={{marginBottom:"5px !important"}} src={meter} alt="meterIcon"/>
                            <Typography sx={{lineHeight:"normal !important"}} >{stage===1 ? "Order Dispatch Status" : "Update Dispatch Status"}</Typography>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        {stage ===1 && (
            <>
            
            {orderList && orderList.data && orderList.data.length > 0 ? (
                orderList.data.map((item) => (
                <>
                    <Grid container spacing={1} mb={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card className="order-card">
                                <CardHeader
                                    avatar={
                                        <Box sx={{ borderRight: "1px solid #D4E0EC !important",paddingRight: "10px"}}>
                                            <Typography variant="h5" className="fs-15 fw-400">{item?.ordered_by_user_name}</Typography>
                                            <Typography variant="h5" className="fs-15 fw-400">{item?.cluster_name}</Typography>
                                        </Box>
                                    }
                                    title="Main Order ID"
                                    subheader={item?.main_order_id}
                                />
                               
                                        <Box sx={{ paddingLeft: "20px", flexShrink: 0 }}>

                                        <Button
                                            className={'white-btn my-auto fw-600'}
                                            sx={{ fontSize: "12px !important" }}
                                        >
                                            Packed
                                        </Button>

                                        <a href="#" onClick={() => handleUpdateStatus(2, item)}>Update Status</a>

                                    </Box>
                                   
                                
                                    <CardContent>
                                    <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow:"none !important" }}>
                                        <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="cstm-tablecell1">Order Date</TableCell>
                                                        <TableCell className="cstm-tablecell2">{formatDateString(item?.order_date)}</TableCell>
                                                        <TableCell className="cstm-tablecell1">Order Total</TableCell>
                                                        <TableCell className="cstm-tablecell2"> ₹ {calculateTotal(item?.order_items)} ({((item?.order_items).length)} Material)</TableCell>
                                                    </TableRow>
                                                     <TableRow>
                                                        {/* <TableCell className="cstm-tablecell1">ETA</TableCell>
                                                        <TableCell className="cstm-tablecell2"></TableCell> */}
                                                        <TableCell className="cstm-tablecell1">Payment</TableCell>
                                                        <TableCell className="cstm-tablecell2">{(item.payments).length > 0 ? "Completed" : "Not Completed"}</TableCell>
                                                        <TableCell className="cstm-tablecell1">Payment on</TableCell>
                                                        <TableCell className="cstm-tablecell2">{(item.payments).length > 0 ? formatDateString(item.payments[0]?.payment_on) : ""}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        
                                                        <TableCell className="cstm-tablecell1">Amount Paid</TableCell>
                                                        <TableCell className="cstm-tablecell2">{item.payments[0]?.amount_paid}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        
                                                        {/* <TableCell className="cstm-tablecell1">Delivery Contact Name</TableCell>
                                                        <TableCell className="cstm-tablecell2">-</TableCell> */}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                       
                                            <Button 
                                                //disabled={item.invoices?.length === 0} 
                                                //onClick={() => handleInvoiceView(item.invoices)} 
                                                className={item.invoices?.length > 0 ? "success-btn" : "white-btn"} 
                                                sx={{
                                                    padding: "10px 20px 10px 20px !important",
                                                    minWidth: "110px !important",
                                                    fontSize: "13px !important",
                                                    gap: "5px",
                                                    textTransform: "capitalize"
                                                }}>
                                                View GFC
                                            </Button>
                                       </Box>
                                </CardActions>
                                
                                
                                
                                
                                                               
                            </Card>
                        </Grid>
                    </Grid>
                </>
            ))) : (
                    <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', mt: 2 }}>
                        No Orders Found
                    </Typography>
                )} 

                </>
        )}
        {stage === 2 && (
            
            <>
            <Grid container spacing={1} mb={2}>
            <Grid item xs={12}>
                <Card className="order-card">
                    <CardHeader
                        avatar={
                            <Box sx={{ borderRight: "1px solid #D4E0EC !important",paddingRight: "10px"}}>
                                <Typography variant="h5" className="fs-15 fw-400">{currentItem?.ordered_by_user_name}</Typography>
                                <Typography variant="h5" className="fs-15 fw-400">{currentItem?.cluster_name}</Typography>
                            </Box>
                        }
                        title="Main Order ID"
                        subheader={currentItem?.main_order_id}
                    />
                     <CardContent>
                        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: "none" }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                <label>Dispatch Status <span style={{ color: "red" }}>*</span></label>
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        name="dispatch_status"
                                        value={updateStatusRequest?.dispatch_status}
                                    >
                                        <MenuItem value="Packed">Packed</MenuItem>
                                        <MenuItem value="Dispatched">Dispatched</MenuItem>
                                        <MenuItem value="Delivered">Delivered</MenuItem>
                                    </TextField>
                                    <FormHelperText error>
                                        {errors?.dispatch_status}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Dispatch Date <span style={{ color: "red" }}>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                    <DatePicker
                                                name="dob"
                                                minDate={dayjs()}
                                                value={updateStatusRequest?.dispatch_date ? dayjs(updateStatusRequest?.dispatch_date) : ""}
                                                slotProps={{ textField: { fullWidth: true } }}
                                                onChange={(newValue) => {
                                                    setDispatchDate(newValue)
                                                    updateError('dob')
                                                }
                                                }

                                            />
                                   
                                    </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText error>
                                    {errors?.dispatch_date}
                                </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Transportation Number</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="transportation_number"
                                        value={updateStatusRequest?.transportation_number}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    <FormHelperText error>
                                        {errors?.transportation_number}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Delivery Person Name</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="delivery_person_name"
                                        value={updateStatusRequest?.delivery_person_name}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{ maxLength: 15 }}
                                    />
                                    <FormHelperText error>
                                        {errors?.delivery_person_name}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Delivery Contact Number</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="delivery_contact_number"
                                        value={updateStatusRequest?.delivery_contact_number}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 10,
                                        }}  
                                    />
                                    <FormHelperText error>
                                        {errors?.delivery_contact_number}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="space-between">
                                    <Button variant="outlined" color="secondary" onClick={()=>handleUpdateStatus(1,[])}>Cancel</Button>
                                    <Button variant="contained" color="primary" type="submit">Save</Button>
                                </Grid>
                            </Grid>
                            </form>
                        </Paper>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            </>

        
        )}
        </Body>
       

    )  
}

export default OrderDispatchStatus