import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const updateDealorApi = createAsyncThunk(
    'auth/update-dealor',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.patch(`/api/v1/dealor/update_dealer`, data,
                { 
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const updateDealorSlice = createSlice({
    name: 'update-dealor',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(updateDealorApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateDealorApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(updateDealorApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const updateDealorReducer = updateDealorSlice.reducer