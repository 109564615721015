import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import dealerIconSvg from "../../assets/svg/dealer-registration-svg.png"
import quotationIconSvg from "../../assets/svg/quotation-svg.png"
import meterIconSvg from "../../assets/svg/meter-svg.png"
import gfcIconSvg from "../../assets/svg/gfc-svg.png"
import materialIconSvg from "../../assets/svg/material-svg.png"
import layoutIconSvg from "../../assets/svg/layout-svg.png"
import refundIconSvg from "../../assets/svg/refund-svg.png"
const QuickAction = () => {
    const navigate = useNavigate()
    return (
        <Grid item xs={12} sm={12} md={2} lg={2} xl={3}>

                <Box className="d-flex flex-wrap  bg-white" sx={{gap:"10px",padding:"12px !important"}}>
                  <Box className="w-100">
                    <Typography variant="h6" className="fs-15 fw-600">Quick Actions</Typography>
                  </Box>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#EDFFFA" , borderColor:"#E1FFF7 !important"}}  >
                    <Box className="m-auto">
                        <img src={dealerIconSvg} alt="box"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Dealer Registration</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#F5F4F4" , borderColor:"#F5F4F4 !important"}} >
                    <Box className="m-auto">
                        <img src={quotationIconSvg} alt="quotationSvg"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Quotations</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#FFF1E7" , borderColor:"#FFF1E7 !important"}} >
                    <Box className="m-auto">
                        <img src={meterIconSvg} alt="meterIconSvg"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Reports & Dashboard</Typography>
                    </Box>
                  </Card>
                  <Card onClick={() => navigate("/layout")} style={{cursor:"pointer"}} className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#FAF1FF" , borderColor:"#FAF1FF !important"}} >
                    <Box className="m-auto">
                        <img src={gfcIconSvg} alt="gfcIconSvg"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>GFC Status</Typography>
                    </Box>
                  </Card>
                  <Card  onClick={() => navigate("/inventory")} style={{cursor:"pointer"}} className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#FEF9E7" , borderColor:"#FEF9E7 !important"}} >
                    <Box className="m-auto">
                        <img src={materialIconSvg} alt="materialIconSvg"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Material</Typography>
                    </Box>
                  </Card>
                  <Card onClick={() => navigate("/layout")} style={{cursor:"pointer"}} className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#F1F8FF" , borderColor:"#F1F8FF !important"}} >
                    <Box className="m-auto">
                      <img src={layoutIconSvg} alt="layoutIconSvg"/>
                      <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Layout Designing Plan</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn `} sx={{background:"#FFF7F7" , borderColor:"#FFF7F7 !important"}} >
                    <Box className="m-auto">
                        <img src={refundIconSvg} alt="refundIconSvg"/>
                        <Typography variant="h6" className="fs-13 text-center" sx={{lineHeight:"17px"}}>Refund Replacement Request</Typography>
                    </Box>
                  </Card>
                </Box>
          </Grid>
    )
}

export default QuickAction;