import SpeedIcon from "@mui/icons-material/Speed";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

export const menuItems = [
    {
        title: 'Home',
        icon: <SpeedIcon />,
        key: 'homeAccordion',
        subItems: [
            { title: 'Dashboard', path: '/' }
        ]
    },
    {
        title: 'Project Monitoring',
        icon: <PersonOutlineOutlinedIcon />,
        key: 'projectMasteringAccordion',
        subItems: [
            { title: 'Layout Management', path: '/layout' },
        ]
    },
    {
        title: 'Order Management',
        icon: <WidgetsOutlinedIcon />,
        key: 'orderMasteringAccordion',
        subItems: [
            { title: 'View Order', path: '/order', addpath : '/order/view/' },
            { title: 'Upload/View order receipt', path: '/order/receipt' },
           //{ title: 'Update Dispatch Status', path: '/order/dispatch-status' },
           { title: 'View Dispatch Status', path: '/order/status' },
        ]
    },
    {
        title: 'Inventory Management',
        icon: <InventoryOutlinedIcon />,
        key: 'inventoryManagementAccordion',
        path: '/inventory',
    },

    {
        title: 'Profile',
        icon: <AccountCircleIcon />,
        key: 'profileAccordion',
        path: '/user/profile',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        onclick: 'logout',
    }
   
  ];