import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import InputDrawing from '../../assets/img/InputDrawingicon.png'
import Style from "../../styles/home.module.scss";
import SchematicLayout from '../../assets/img/SchematicLayouticon.png'
import { useDispatch, useSelector } from "react-redux";
import { inputDrawingApi } from "../../store/home/layoutTable";
import { countApprovalAdmin, countGFCAvailable, countSchematicAvailable, isSchematicAvailable } from "../../utils/orderItemArrayManipulation";


const DashboardLayoutMangementBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {result : inputDrawing} = useSelector((state) => state?.inputDrawingReducer);
    useEffect(() => {
        dispatch(inputDrawingApi());
    },[])
    return (
            <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card sx={{boxShadow: "0px 4px 24px 0px #E0F0FF !important"}}>            
                            <Box className={Style?.headerSectionWhite}>
                                <Typography className={Style?.cardHeadingWhite} variant="h6">
                                Layout & Management
                                </Typography>
                            </Box>

                            <Stack sx={{ mt: 2 }}>
                                <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Card className={Style?.homeCard1} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate('/layout')} style={{cursor:"pointer"}}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <img src={InputDrawing} alt="inputDrawing" />
                                        <Stack>
                                        <Typography className={Style.cardTitle} variant="p">
                                            Input Drawing
                                        </Typography>
                                        <Typography className={Style.cardValue} variant="h5">
                                            {countApprovalAdmin(inputDrawing?.data)}
                                        </Typography>
                                        </Stack>
                                    </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Card className={Style?.homeCard2} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate('/layout')} style={{cursor:"pointer"}}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <img src={SchematicLayout} alt="schemaLayoutIcon"/>
                                        <Stack>
                                        <Typography variant="p"  className={Style.cardTitle}>
                                            Schematic Layout
                                        </Typography>
                                        <Typography variant="h5"  className={Style.cardValue}>
                                            {inputDrawing?.approve_by_admin === true ? countSchematicAvailable(inputDrawing?.results?.data) : 0}
                                        </Typography>
                                        </Stack>
                                    </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Card className={Style?.homeCard3} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate('/layout')} style={{cursor:"pointer"}}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <img src={SchematicLayout} alt="schematicLayoutIcon"/>
                                        <Stack>
                                        <Typography variant="p" className={Style.cardTitle}>
                                            GFC
                                        </Typography>
                                        <Typography variant="h5" className={Style.cardValue}>
                                            {inputDrawing?.approve_by_admin === true ?  countGFCAvailable(inputDrawing?.results?.data) : 0}
                                        </Typography>
                                        </Stack>
                                    </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Card className={Style?.homeCard4} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate('/layout')} style={{cursor:"pointer"}}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <img src={SchematicLayout} alt="schematicLayoutIcon"/>
                                        <Stack>
                                        <Typography variant="p" className={Style.cardTitle}>
                                            Design Estimate
                                        </Typography>
                                        <Typography variant="h5" className={Style.cardValue}>
                                            {inputDrawing?.approve_by_admin === true ? countSchematicAvailable(inputDrawing?.results?.data) : 0}
                                        </Typography>
                                        </Stack>
                                    </Stack>
                                    </Card>
                                </Grid>
                                </Grid>
                            </Stack>
                            </Card>      
                        </Grid>
            </>
    )

}

export default DashboardLayoutMangementBlock