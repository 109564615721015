import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, InputAdornment, Stack,  TextField,  Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom';
import meter from "../../assets/img/meter.png";
import { showToast } from '../../toast/toast';

import AddInventory from '../reuseable/AddInventoryModal';
import { getSupplierApi } from '../../store/supplier';
import { getCategoryApi } from '../../store/category';
import { deleteInventoryApi, getInventoryApi } from '../../store/inventory';
import DeleteConfirmationModal from '../reuseable/DeleteConfirmationModal';
import DataTable from '../reuseable/DataTable';
import SearchIcon from "@mui/icons-material/Search";


const Inventory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [ rowsData, setRowsData] =useState([]);
    const [inputDrawingLoading, setInputDrawingLoading] = useState(false);
    const {loading:inventoryLoading, error:inventoryError, result:inventoryList}  = useSelector(state => state?.inventoryReducer);
    const [allInventoryData, setAllInventoryData] = useState([]);
    const columns = [
        { field: "supplier_company_name", headerName: "Sub-Vendor/Supplier", width:200},  
        { field: "category_name", headerName: "Category",},
        {
            field: 'snap',
            headerName: 'Image',
            width: 200,
            renderCell: (params) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {params.value ? (
                  <img
                    src={params.value}
                    alt="snap"
                    style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <span>No Image</span>
                )}
              </div>
            ),
        },
        { field: "kia_specification", headerName: "Vendor Specification", width:200},
        { field: "unit_of_measurement", headerName: "Unit",width:150}, 
        { field: "vendor_price", headerName: "Dealer Price", width:170, sortComparator: (v1, v2) => Number(v1) - Number(v2),headerAlign: "left"},
        { field: "supplier_price", headerName: "Supplier Price", width:170, sortComparator: (v1, v2) => Number(v1) - Number(v2),headerAlign: "left"},
        { field: "quantity", headerName: "Quantity", width:150, sortComparator: (v1, v2) => Number(v1) - Number(v2),headerAlign: "left"},
    ];

    const [editRowData, setEditRowData] = useState({});
    const editUser = (id) => {
        const dataToEdit = rowsData.find((item) => item.id === id);
        setEditRowData(dataToEdit)
        setOpenInventoryModal(true)
    }

    const deleteInventory = (deleteRowId) => {
        setOpenModal(true);
        setDeleteId(deleteRowId)
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };
  

    const handleConfirmDelete = async() => {
        const dataTodelete = {
            id: deleteId
        }
        const deleteData = await dispatch(deleteInventoryApi(dataTodelete));
        if(deleteData?.error){
          const { message } = deleteData?.payload;
        }else{
          showToast(deleteData?.payload?.message, 1);
          dispatch(getInventoryApi());
          setDeleteId(null)
          handleCloseModal()
        }
    };
    
    //Code for open inventory modal
    const [openInventoryModal, setOpenInventoryModal] = useState(false);
    const handleInventoryOpen = () => setOpenInventoryModal(true);
    const handleInventoryClose = () => setOpenInventoryModal(false);

    const closePopUp = () => {
        setEditRowData({});
        setOpenInventoryModal(false);
    }
    useEffect(() => {
        //Call api for supplier
        dispatch(getSupplierApi())
        dispatch(getCategoryApi())
        dispatch(getInventoryApi())
    },[dispatch])

    const handleChangeSearch = (e) => {
        e.preventDefault();
        const searchTerm = e.target.value.toLowerCase(); 
        if (!searchTerm) {
            const updatedArray = inventoryList?.data.map(obj => ({
                ...obj,
                snap: obj.snap && obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
                vendor_price : obj.vendor_price !=='' ? `${obj.vendor_price}` : 'NA',
                supplier_price : obj.supplier_price !=='' ? `${obj.supplier_price}` : 'NA',
              }));
            setRowsData(updatedArray);
          return;
        }
        const filteredData = inventoryList?.data?.filter((row) => {
            const supplier_company_name = (row.supplier_company_name || "").toLowerCase();
            const name = (row.category_name || "").toLowerCase();
            return (
              supplier_company_name.includes(searchTerm) ||
              name.includes(searchTerm)
            );
          }).map(obj => ({
            ...obj,
            snap: obj.snap && obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
            vendor_price : obj.vendor_price !=='' ? `${obj.vendor_price}` : 'NA',
            supplier_price : obj.supplier_price !=='' ? `${obj.supplier_price}` : 'NA',
          }));
          
          setRowsData(filteredData);
    
    };

    useEffect(() => {
        if (inventoryList?.data) {
            const updatedArray = inventoryList.data.map(obj => ({
                ...obj,
                snap: obj.snap ? `${process.env.REACT_APP_BASE_URL}${obj.snap}` : null,
                vendor_price: obj.vendor_price !== '' ? `${obj.vendor_price}` : 'NA',
                supplier_price: obj.supplier_price !== '' ? `${obj.supplier_price}` : 'NA',
            }));
            setRowsData(updatedArray);
        } else {
            setRowsData([]);
        }
    }, [inventoryList]);


    return (
        <Body>

            <Grid container alignItems="center" flexWrap={"wrap"} spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <Stack direction="row" spacing={1}  sx={{ my: 2 }} alignItems="center">
                        <img src={meter} alt="Meter" />
                        <Typography variant="h6" className="pageTitle">Inventory</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8}>
                    <Grid container flexWrap={"nowrap"} spacing={1} justifyContent={"end"}>
                        <Grid item xs={12} sm={12} md={6} lg={6}  sx={{ marginRight: {md: 0.5,sm: 0,}, }}>
                            <TextField fullWidth size="small" 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Search by Vendor or Category" onChange={handleChangeSearch}
                            />
                        </Grid>
                        <Grid item sm="auto">
                            <Button variant="contained" onClick={()=>setOpenInventoryModal(true)}>
                                Add Inventory
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <DataTable  data={rowsData ? rowsData : []} columns={columns} deleteUser={deleteInventory} editUser={editUser} type='inventory'/>
                    </Grid>
                </Grid>
            </Box>
            <AddInventory open={openInventoryModal} handleClose={closePopUp} editRowData={editRowData}/>
            <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}  handleConfirm={handleConfirmDelete}  deleteType='Inventory'/>
        </Body>
        
    );
}
export default Inventory
