import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios';
import { getAccessToken } from '../../utils/getAccessToken';

export const getUserDetailApi = createAsyncThunk(
    'userProfile/getUserDetail',
    async (_, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.get(`/api/v1/login/get_user_detail/`,{
                 headers: {'Authorization': `Bearer ${accessToken}`} ,  
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const getUserDetail = createSlice({
    name: 'get-userDetail',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserDetailApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserDetailApi.fulfilled, (state, action) => {
            state.loading = false;
            state.userDetailData = action.payload;
            state.error = null;
        });
        builder.addCase(getUserDetailApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
});

export const getUserDetailReducer = getUserDetail.reducer;
