export const orderStatus = {
    order_placed: 'Order Placed',
    order_validated: 'Order Validated',
    order_accepted: 'Order Accepted',
    order_reviewed: 'Order Reviewed',
    order_assigned: 'Order Assigned', 
    order_dispatched: 'Order Dispatched',
    order_delivered: 'Order Delivered',
    order_cancelled: 'Order Cancelled',
    refund_initiated: 'Refund Initiated',
    refund_approved: 'Refund Approved',
    refund_rejected: 'Refund Rejected',
    refund_completed: 'Refund Completed',
    order_packed : 'Order Packed',
    order_shipped : ' Order Shipped',

}