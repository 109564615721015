import * as yup from "yup";


export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
  password: yup.string().matches().required("Password is required"),
  
})
.required();


export const cadUploadSchema = yup.object({
    cadFile: yup.mixed()
      .required("CAD file is required")
      .test("fileType", "Only DWG files are allowed", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
        // Check if value is an array and contains files
        if (value && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] && value[i].size > 10 * 1024 * 1024) {
              return false;
            }
          }
          return true;
        }
        return false;
      }),
  }).required();



export const pdfUploadSchema = yup.object({
  pdfFile: yup.mixed()
      .required("PDF file is required")
      .test("fileType", "Only PDF files are allowed", (value) => {
          // Check if value is an array and contains files
          if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                  if (value[i] && value[i].type !== "application/pdf") {
                      return false;
                  }
              }
              return true;
          }
          return false;
      })
      .test("fileSize", "Each file should be less than 10MB", (value) => {
          // Check if value is an array and contains files
          if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                  if (value[i] && value[i].size > 10 * 1024 * 1024) {
                      return false;
                  }
              }
              return true;
          }
          return false;
      }),
}).required();



export const schematicLayoutSchema = yup.object({
  pdfFile: yup.mixed().test('pdfFile', "PDF file is required", function (value) {
    return value && this.options.context?.addSchematicLayout?.pdfFile?.length > 0 || this.options.context?.addSchematicLayout?.pdfFile?.length > 0;
  }),
  cadFile: yup.mixed().test('cadFile', "CAD file is required", function (value) {
    return value && this.options.context?.addSchematicLayout?.cadFile?.length > 0 || this.options.context?.addSchematicLayout?.cadFile?.length > 0;
  }),

  designEstimateFile: yup.mixed().test('designEstimateFile', "Design Estimate is required", function (value) {
    const { isRevisedRequest, addSchematicLayout } = this.options.context || {};
    if (!isRevisedRequest && (!value || !addSchematicLayout?.designEstimateFile?.length)) {
      return false; // Invalid if isRevisedRequest is false and designEstimateFile is not provided
    }
    return true; // Valid if isRevisedRequest is true or designEstimateFile is provided
  }),
  previous_estimate: yup.boolean().test('previous_estimate', "Please select Send Previous One or upload New Attach Design Estimate", function (value) {
    const { isRevisedRequest, addSchematicLayout } = this.options.context || {};
    if (isRevisedRequest && !value && (!addSchematicLayout?.designEstimateFile?.length)) {
      // Show error only if isRevisedRequest is true and neither previous_estimate nor designEstimateFile is provided
      return false;
    }
    return true;
  })
}).required();

  export const GFCSchema = yup.object({
    pdfFile: yup.mixed().test('pdfFile', "PDF file is required", function (value) {
    return value && this.options.context?.addGFCData?.pdfFile?.length > 0 || this.options.context?.addGFCData?.pdfFile?.length > 0;
    }),
    cadFile: yup.mixed().test('cadFile', "CAD file is required", function (value) {
      return value && this.options.context?.addGFCData?.cadFile?.length > 0 || this.options.context?.addGFCData?.cadFile?.length > 0;
    }),
  }).required();


  export const addInventorySchema = yup.object().shape({
    supplier_company_id: yup.lazy((value, { parent }) =>
      parent.supplier_id ? yup.string() : yup.string().required('Supplier Organization is required')
    ),
    supplier_id: yup.string(),
    category_id: yup.string().required('Material Category is required'),
    kia_specification: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    }).max(100, "Supplier Specification cannot exceed 100 characters").required("Supplier Specification is required"),
    unit_of_measurement: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    }).max(30, "Unit of Measurement cannot exceed 30 characters").required("Unit of Measurement is required"),
    quantity: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable()
      .required('Quantity is required')
      .positive('Quantity must be a positive number')
      .integer('Quantity must be an integer')
      .typeError('Quantity must be a number'),
    product_description: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    }).max(100, "Product Description cannot exceed 100 characters").required("Product Description is required"),
  });

export const addRemarkSchema = yup.object().shape({
  remark: yup.string().required('Please provide a comment'),
});

export const updateProfileSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(15, 'First Name cannot exceed 15 characters')
  .required("Please provide First Name"),

  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  })
  .max(15, 'Last Name cannot exceed 15 characters')
  .required("Please provide Last Name"),
  
  phone: yup.string().matches(/^[6-9][0-9]{9}$/, "Phone  must start with 9, 8, 7, or 6 and be exactly 10 digits").required("Please provide Phone"),
}).required();

export const updateOrderStatusSchema = yup.object({
  dispatch_status: yup.string().required("Please provide Dispatch Status"),
  dispatch_date: yup.string().required("Please provide Dispatch Date"),
  transportation_number: yup
  .string()
  .nullable()
  .notRequired()
  .test('only-letters-and-spaces', 'Only letters (a-z, A-Z) and spaces are allowed, no special characters', (value) => {
    if (!value) return true;
    return /^[a-zA-Z\s]+$/.test(value);
  })
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    if (!value) return true;
    return value === value.trim();
  })
  .test('max-length', 'Transportation Number cannot exceed 50 characters', (value) => {
    if (!value) return true;
    return value.length <= 50;
  }),
  delivery_person_name: yup
  .string()
  .nullable()
  .notRequired()
  .test('only-letters-and-spaces', 'Only letters (a-z, A-Z) and spaces are allowed, no special characters', (value) => {
    if (!value) return true;
    return /^[a-zA-Z\s]+$/.test(value);
  })
  .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    if (!value) return true;
    return value === value.trim();
  })
  .test('max-length', 'Delivery Person Name cannot exceed 15 characters', (value) => {
    if (!value) return true;
    return value.length <= 15;
  }),
delivery_contact_number: yup
  .string()
  .nullable()
  .notRequired()
  .test('valid-phone', 'Delivery Contact Number must start with 9, 8, 7, or 6 and be exactly 10 digits', (value) => {
    // Allow empty values, and only apply the pattern if there is a value
    if (!value) return true;
    return /^[6-9][0-9]{9}$/.test(value);
  })   
}).required(); 