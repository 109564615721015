export const  isAllOrderValidationTrue = (orderItems) => {
    for (let item of orderItems) {
      if (!item.order_validation) {
        return false;
      }
    }
    return true;
}


export const  isAllOrderAcceptedTrue = (orderItems) => {
  for (let item of orderItems) {
    if (!item.order_accepted) {
      return false;
    }
  }
  return true;
}

export const countSchematicAvailable = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.schematic_status) {
      count++;
    }
  }
  return count;
}

export const countGFCAvailable = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.gfc_status) {
      count++;
    }
  }
  return count;
}


export const countApprovalAdmin = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.approve_by_admin) {
      count++;
    }
  }
  return count;
}


// export const countSchematicAvailable = (orderItems) => {
//   let count = 0;
//   for (let item of orderItems) {
//     if (item.schematic_status) {
//       count++;
//     }
//   }
//   return count;
// }