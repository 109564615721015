import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const getRevisedGFCApi = createAsyncThunk(
    'layout/revised-gfc',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/layout/get_revised_gfc/',data,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            );
            return response?.data;
        }
        catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const resetRevisedGFC = createAction('layout/reset-revised-gfc')



const revisedGFCSlice = createSlice({
    name: 'revised-gfc',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getRevisedGFCApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRevisedGFCApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.data
            state.message = action?.payload?.message
        })
        builder.addCase(getRevisedGFCApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        builder.addCase(resetRevisedGFC, (state, action) => {
            state.result = []
            state.loading = false
            state.error = null
            state.message = ""
        })
    },
})

export const revisedGFCReducer = revisedGFCSlice.reducer