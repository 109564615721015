import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Dialog,
  Slide,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { showToast } from "../../toast/toast";
import { deleteInventoryApi, getInventoryApi } from "../../store/inventory";
import { ordersApi } from "../../store/orders/orders";
import OrderStatusModal from "./OrderStatusModal";
import Body from "../../components/Body";
import orderstatusimg from "../../assets/img/order-status-img.png";
import mapIcon from "../../assets/svg/mapIcon.svg";
import box from "../../assets/svg/box.svg";
import "../OrderManagement/ordermanagement.scss";
import { ReactComponent as PurposeIcon} from "../../assets/svg/purposeIcon.svg"
import { calculateTotal } from "../../utils/priceManipulation";


const OrderStatus = () => {
  const [openModal, setOpenModal] = useState(false);
  const [allOrderData, setAllOrderData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result: orderList } = useSelector((state) => state?.orders);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const handleModalOpen = (item) => {
    setSelectedOrder(item);
    setOpenModal(true);
  } 
  const handleModalClose = () =>{
    setSelectedOrder(null);
    setOpenModal(false);
  } 

  const handleNavigate = (outletId) => {
    localStorage.setItem("selectedOutletIdToView", outletId);
    navigate(`/layout/view`);
  };

  const viewOrder = (id) => {
    localStorage.setItem("selectedOrderIdView", id);
    navigate(`/order/view/`);
  };

  const deleteInventory = (deleteRowId) => {
    dispatch(deleteInventoryApi({ id: deleteRowId }))
      .then((response) => {
        if (response?.payload?.status) {
          showToast("Inventory deleted successfully", 1);
          dispatch(getInventoryApi());
        } else {
          showToast("Failed to delete inventory", 2);
        }
      })
      .catch((error) => {
        showToast(error.message, 2);
      });
  };

  useEffect(() => {
    dispatch(ordersApi());
  }, [dispatch]);

  useEffect(() => {
    setAllOrderData(orderList?.data);
  }, [orderList]);

  return (
    <Body>
      <Stack direction="row" spacing={10} sx={{ my: 2 }} justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <img src={box} alt="box" />
          <Typography color="#05141F" fontSize="17px" fontWeight={400} ml={1} fontFamily="'KiaSignatureRegular'">
            View dispatch status
          </Typography>
        </Stack>
      </Stack>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} className="gap-20 flex-wrap">
          {allOrderData?.map((item) => (
            <Box className="status-card">
              <Box>
                <img src={orderstatusimg} alt="order status" />
              </Box>
              <Box className="d-flex justify-content-between mt-20">
                <Typography variant="h5" className="fs-15 fw-600 my-auto text-blue">
                  {item?.main_order_id}
                </Typography>
                {item?.order_status && (
                    <Box className="order-status delivered">
                  <Typography variant="h5" className="fs-11 fw-600">{item?.order_status}</Typography>
                  {item?.order_status === "Order Delivered" && ( <CheckCircleIcon />  )}
                </Box>
                ) }
              </Box>
              <Divider className="mt-10" />
              <Box className="d-flex gap-5 mt-10">
                <img src={mapIcon} alt="map icon" style={{ width: 15, height: 20 }} />
                <Typography variant="h5" className="fs-13 fw-400 my-auto">
                  {item?.ordered_by_user_name	} {item?.cluster_name	}
                </Typography>
              </Box>
              <Box className="d-flex gap-5 mt-10">
              <Box className="d-flex justify-center" sx={{ width: 15 }}>
              <PurposeIcon/>
              </Box>
                <Typography variant="h5" className="fs-13 fw-400 my-auto">
                  Purpose: 2S Upgrade
                </Typography>
              </Box>
              <Box className="d-flex justify-content-between ViewDetail mt-10" sx={{ alignItems: "center" }}>
                <Typography variant="h5" className="fs-13 fw-400">₹ {calculateTotal(item?.order_items)}</Typography>
                <Typography
                  variant="h5"
                  onClick={() => handleModalOpen(item)}
                  className="fs-11 fw-400 cursor-pointer d-flex gap-5"
                  sx={{ alignItems: "center" }}
                >
                  View Detail
                  <ArrowForwardIosOutlinedIcon sx={{ width: 12, height: 12 }} />
                </Typography>
              </Box>
            </Box> 
          ))}
            {/* <Box className="status-card">
              <Box>
                <img src={orderstatusimg} alt="order status" />
              </Box>
              <Box className="d-flex justify-content-between mt-20">
                <Typography variant="h5" className="fs-15 fw-600 my-auto text-blue">
                  AKN23415
                </Typography>
                <Box className="order-status delivered">
                  <Typography variant="h5" className="fs-11 fw-600">Completed</Typography>
                  <CheckCircleIcon />
                </Box>
              </Box>
              <Divider className="mt-10" />
              <Box className="d-flex gap-5 mt-10">
                <img src={mapIcon} alt="map icon" style={{ width: 15, height: 20 }} />
                <Typography variant="h5" className="fs-13 fw-400 my-auto">
                  Amit KIA Noida
                </Typography>
              </Box>
              <Box className="d-flex gap-5 mt-10">
              <Box className="d-flex justify-center" sx={{ width: 15 }}>
              <PurposeIcon/>
              </Box>
                <Typography variant="h5" className="fs-13 fw-400 my-auto">
                  Purpose: 2S Upgrade
                </Typography>
              </Box>
              <Box className="d-flex justify-content-between ViewDetail mt-10" sx={{ alignItems: "center" }}>
                <Typography variant="h5" className="fs-13 fw-400">₹ 1,79,000</Typography>
                <Typography
                  variant="h5"
                  onClick={handleModalOpen}
                  className="fs-11 fw-400 cursor-pointer d-flex gap-5"
                  sx={{ alignItems: "center" }}
                >
                  View Detail
                  <ArrowForwardIosOutlinedIcon sx={{ width: 12, height: 12 }} />
                </Typography>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
      </Box>

      {/* Order Status Modal */}
      <OrderStatusModal open={openModal} onClose={handleModalClose} selectedOrder={selectedOrder} />
    </Body>
  );
};

export default OrderStatus;
