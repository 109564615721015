import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

    export const getApplicantsApi = createAsyncThunk(
        'dealor/get-applicant',
        async (_, { rejectWithValue, getState }) => {
            try {
                const accessToken = getAccessToken(getState);
                const response = await AuthAxios.get(`/api/v1/super_admin/applicants/`,
                    { 
                        headers: {'Authorization': `Bearer ${accessToken}`}  
                    }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

const getApplicantSlice = createSlice({
    name: 'applicant',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getApplicantsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getApplicantsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getApplicantsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const getApplicantSliceReducer = getApplicantSlice.reducer