import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, Grid, Divider, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { convertToFormData } from '../convertFormData';
import { getSchematicLayoutByClusterIdApi } from '../../store/home/getSchematicLayout';
import { approvePaymentApi, getDesignEstimateApi } from '../../store/home/getDesignEstimate';
import { useDispatch } from 'react-redux';
import { showToast } from '../../toast/toast';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'


export default function DialogModal({ formID, open, handleCloseConfirmModal, estimate_id, utr_no, utr_file, type }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    handleCloseConfirmModal();
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  // Get the extension of the utr_file
  const fileExtension = utr_file ? getFileExtension(utr_file) : null;

  const approvePayment = async (estimate_id, utr_no) => {
    setLoading(true);
    const data = {
      estimate_id: estimate_id,
    };
    const dataToSubmit = convertToFormData(data);
    try {
      await dispatch(approvePaymentApi(dataToSubmit));
      getD();
      setLoading(false);
      handleCloseConfirmModal();
    } catch (error) {
      showToast(error, 2);
      setLoading(false);
      handleCloseConfirmModal();
    }

    return () => {
      handleClose();
    };
  };

  const getD = async () => {
    const dataToSubmit = {
      form_id: formID,
    };
    try {
      await dispatch(getDesignEstimateApi(dataToSubmit));
      //setData(response?.payload?.data);
    } catch (error) {
      showToast(error, 2);
    }
  };

  const getTypeText = (type) => {
    if (type === 'verify') {
      return "Please confirm your approval";
    } else if (type === 'invoice') {
      return "Invoice View";
    } else {
      return "Payment View";
    }
  };

  return (
    <React.Fragment>
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          // Do nothing on backdrop click
        } else {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Box><strong>{getTypeText(type)}</strong></Box>
          {(type === 'view' || type === 'invoice') && (
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          {type !== 'invoice' && (
            <Grid item xs={6}>
              <DialogContentText id="alert-dialog-description">
                UTR : <strong>{utr_no}</strong>
              </DialogContentText>
            </Grid>
          )}
          
          <Grid item xs={1}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="center" alignItems="center" position="relative">
            {fileExtension === 'pdf' ? <img alt="Your Image" style={{ maxWidth: '50px' }}  src='https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/invoice.png' className="my-auto" />:  <img src={`${process.env.REACT_APP_BASE_URL}${utr_file}`} alt="Your Image" style={{ maxWidth: '50px' }} />}
              <IconButton
                color="inherit"
                aria-label="maximize"
                onClick={() => handleDownload(new Date() + 'payment_file', utr_file)}
              >
                <FullscreenIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {type === 'verify' && (
        <>
          <Divider sx={{ width: '100%', mb: 2 }} />
          <DialogActions
            sx={{ display: 'flex', justifyContent: 'center', gap: 2, p: 2 }} // Arrange buttons side by side with space in between
          >
            <Button
              onClick={handleClose}
              color='error'
              sx={{ backgroundColor: '#f44336', color: '#fff', '&:hover': { backgroundColor: '#d32f2f' } }} // Background color
            >
              Close
            </Button>
            <Button
              onClick={() => approvePayment(estimate_id, utr_no)}
              autoFocus
              color='success'
              sx={{ backgroundColor: '#4caf50', color: '#fff', '&:hover': { backgroundColor: '#388e3c' } }} // Background color
            >
              {loading ? <CircularProgress size={24} /> : 'Agree'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  </React.Fragment>
);
}
