import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { resetPasswordVerifyOtpApi } from "../../store/auth/resetPasswordVerifyOtp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalCss from "../../styles/modal.module.scss";
import checkImg from "../../assets/img/checked.gif";
const schema = yup
  .object({
    new_password: yup.string().required(),
    confirm_password: yup.string().required(),
  })
  .required();

export default function PassowrdChangedSuccess({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_sm}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}
            >
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack alignItems="center" sx={{ my: 2 }}>
              <img
                src={checkImg}
                alt="check"
                style={{
                  width: "80px",
                  height: "80px",
                  marginInline: "auto",
                }}
              />
            </Stack>

            <Box textAlign={"center"}>
              <Stack width={"90%"} margin={"20px auto 0"} spacing={2}>
                <Typography variant="h5" fontWeight={600} marginY={2}>
                  Your password has been successfully changed
                </Typography>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    margin: "30px auto 0 !important",
                    padding: { lg: "16px 10px", xs: "10px" },
                    fontSize: { lg: "18px", xs: "14px" },
                    fontWeight: { lg: "500", xs: "500" },
                    borderRadius: "10px",
                    width: "auto",
                  }}
                  onClick={handleClose}
                > 
                  Back To Login
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}
