import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const getSupplierApi = createAsyncThunk(
    'supplier/get-supplier',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.get(`/api/v1/supplier/supplier_company/` ,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const supplierSlice = createSlice({
    name: 'supplier',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getSupplierApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSupplierApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.data
            state.message = action?.payload?.message
        })
        builder.addCase(getSupplierApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const supplierReducer = supplierSlice.reducer