import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const ordersApi = createAsyncThunk(
    'orders/get-all-orders',
    async (_, { rejectWithValue, getState}) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.get('/api/v1/order/get_all_orders/',
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}          
                }
            )
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

export const validateOrderApi = createAsyncThunk(
    'orders/validate-order',
    async (formData, { rejectWithValue , getState}) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/validate_order/', formData,{
                headers: {'Authorization': `Bearer ${accessToken}`} ,           
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

export const acceptOrderApi = createAsyncThunk(
    'orders/accept-order',
    async (formData, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/accept_order/', formData,{
                headers: {'Authorization': `Bearer ${accessToken}`} ,            
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

export const remarkOrderApi = createAsyncThunk(
    'orders/remark-order',
    async (formData, { rejectWithValue , getState}) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/add_remark/', formData,{
                headers: {'Authorization': `Bearer ${accessToken}`} ,          
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)


export const uploadQuotationApi = createAsyncThunk(
    'orders/upload-quotation-order',
    async (formData, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/upload_main_order_quotation/', formData,{
                headers: {'Authorization': `Bearer ${accessToken}`} ,            
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

export const uploadInvoiceApi = createAsyncThunk(
    'orders/upload-invoice-order',
    async (formData, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/invoice/', formData,{
                headers: {'Authorization': `Bearer ${accessToken}`} ,           
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }  
)

export const downloadOrderApi = createAsyncThunk(
    'order/download', 
    async (_, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.get('/api/v1/order/get_order_report/', {
                headers: {'Authorization': `Bearer ${accessToken}`} ,            
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const assignOrderApi = createAsyncThunk(
    'order/assign', 
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post('/api/v1/order/assign_order_to_supplier/', data, {
                headers: {'Authorization': `Bearer ${accessToken}`} ,           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const ordersSlice = createSlice({
    name: 'orders',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(ordersApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(ordersApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(ordersApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });

        builder.addCase(validateOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(validateOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(validateOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });
        builder.addCase(acceptOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(acceptOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(acceptOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });

        builder.addCase(remarkOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(remarkOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(remarkOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });

        builder.addCase(uploadQuotationApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(uploadQuotationApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(uploadQuotationApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });

        builder.addCase(uploadInvoiceApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(uploadInvoiceApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(uploadInvoiceApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });
        builder.addCase(assignOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(assignOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(assignOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        });
        
    },
})

export const ordersReducer = ordersSlice.reducer