import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import ResetPassword from './ResetPassword';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAuthOtpApi } from '../../store/auth/verifyAuthOtp';
import {authOtpViaEmailApi} from "../../store/auth/authOtpViaEmail"
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from '../../toast/toast';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export default function ResetViaEmailOTP({ open, setOpen, openPreviousModal }) {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const loginState = useSelector(state => state.login);
  const forgetPasswordData = useSelector(state => state.forgetPasswordViaEmail);
  const user_type =  "Innoceans"
  const access_token = forgetPasswordData?.result?.access_token ? forgetPasswordData?.result?.access_token  : localStorage.getItem("access_token")
  const dealorEmail = forgetPasswordData?.result?.email ? forgetPasswordData?.result?.email  : localStorage.getItem("email")
  const handleClose = () => {
    setOpen(false);
    setOtp('');
  };

  const handleVerify = async () => {
    const verifyData = await dispatch(verifyAuthOtpApi({ otp: Number(otp),user_type, type: "email", email: dealorEmail }, { headers: { Authorization: `Bearer ${access_token}` } }))
    if (!verifyData?.payload?.error) {
      localStorage.setItem("access_token", verifyData?.payload?.result?.access_token)
      setResetPasswordModal(true)
      handleClose()
    }else{
      showToast(verifyData?.payload?.message, 2)
    }
  }

  const handleResendOtp = () => {
    dispatch(authOtpViaEmailApi({ type: "email", user_type, email:dealorEmail, "forget_password" : "forget_password"}))
    setOtp('');
    showToast("OTP sent successfully", 1)
  }

  const handleBack = () => {
    handleClose()
    openPreviousModal(true)
  }

  let maskedEmail = "";
  if (dealorEmail) {
    const [localPart, domain] = dealorEmail.split('@');
    maskedEmail = `${localPart.slice(0, 3)}*****@${domain}`;
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
          <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              position={"absolute"}
              left={5} 
              right={5}
              top={5}
              width="100%"
            >
              <IconButton>
                <ArrowBackIosIcon onClick={handleBack}/>
              </IconButton>

              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Verify OTP</Typography>
              <Typography
                className={ModalCss?.subtitle}
                 
              >{`We have sent an OTP to your registered email address `}</Typography>
              <Typography className={ModalCss?.subtitle}> {maskedEmail}</Typography> 
            </Stack>
            {/* <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: "50px 30px" }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px"  }}
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && !e.target.value) {
                      const prevInput = document.querySelector(
                        `input[name="otp-input-${index - 1}"]`
                      );
                      if (prevInput) {
                        prevInput.focus(); 
                        prevInput.value = "";
                      }
                    }
                    if (
                      !/^[0-9]$/.test(e.key) && 
                      e.key !== "Backspace" && 
                      e.key !== "Tab" && 
                      e.key !== "Enter"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name={`otp-input-${index}`}
                />
              )}
            /> */}
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: "50px 30px" }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px"  }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric" />}
              isInputNum={true}
            />
             <Box sx={{ textAlign: "center" }}>
                <Button  sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                  textTransform: "none"
                }} variant='contained' 
                onClick={handleVerify}
                disabled={otp?.length < 4}
                >Verify Account</Button>
             </Box>
             <Stack justifyContent="center" direction="row" alignItems="center" spacing={2} fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Didn’t receive code?</Typography>
              <Button className="black-btn" onClick={() => handleResendOtp()} sx={{textTransform: "none"}}>Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>

      <ResetPassword open={resetPasswordModal} setOpen={setResetPasswordModal} />
    </>
  )
}
