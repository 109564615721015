import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { alpha, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Divider, FormControl, Grid, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Stack, Table, TableBody, TableContainer, TableHead, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/Body';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../../toast/toast';
import { getSupplierApi } from '../../store/supplier';
import { getCategoryApi } from '../../store/category';
import { deleteInventoryApi, getInventoryApi } from '../../store/inventory';
import Vendor from '../../assets/svg/Vendor.svg';
import "../../styles/sidepanel.page.scss";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import tiles from '../../assets/img/tiles.png';
import Style from "../../styles/home.module.scss";
import HRJohnson from '../../assets/svg/HRJohnson.svg';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#98A4A7',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      background:"#ffffff !important",
      [theme.breakpoints.up('md')]: {
        width: '',
      },
    },
  }));
const Assign = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputDrawingLoading, setInputDrawingLoading] = useState(false);
    const {loading:inventoryLoading, error:inventoryError, result:inventoryList}  = useSelector(state => state?.inventoryReducer);
    
    const [allInventoryData, setAllInventoryData] = useState([]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));




    




    const getAllInventoryData= async() => {
        try {
            // const response = await dispatch(inputDrawingApi());
            // const data = response?.payload?.results?.data || [];
            // setAllInventoryData(data);
            //setInputDrawing(data.filter(feedback => feedback?.recived_from_dealor === true));
        } catch (error) {
            showToast(error, 2)
        }
    }

    useEffect(() => {
        getAllInventoryData()
        
    }, []);


    

    // useEffect(() => {
    //     const filteredData = allInputDrawing.filter(row =>
    //         row.dealor_outlet_name?.toLowerCase().includes(searchTermDealer?.toLowerCase()) ||
    //         row.location?.toLowerCase().includes(searchTermLocation?.toLowerCase())
    //     );
    //     setInputDrawing(filteredData);
    // }, [searchTermDealer, searchTermLocation]);




    //Code for open inventory modal




    useEffect(() => {
        //Call api for supplier
        dispatch(getSupplierApi())
        dispatch(getCategoryApi())
        dispatch(getInventoryApi())
      },[dispatch])

    useEffect(() => {
        setAllInventoryData(inventoryList?.data)   
    },[inventoryList])
    return (
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
              <Stack direction="row" alignItems="center">
                    <img src={Vendor} />
                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        Vendors
                    </Typography>
                </Stack>
                <Button variant="contained" className='blue-btn' >Back</Button>
            </Stack>
            <Box className="flex-wrap Vendors-panel-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

                        <Box className="Vendors-side-panel">
                            <Search className='Vendor-search'>
                                <SearchIconWrapper>
                                <SearchIcon sx={{zIndex:"99",fill:"#6D8399"}} color='#6D8399'/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                placeholder="Search by Vendor Name"
                                inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                            <Box className="Vendors-side-panel-list">
                                <Box className={`${Style?.active} Vendors-side-panel-list-item`}>
                                    <Card className={`${Style?.homeCard1} Vendors-side-panel-list-item-card `} sx={{height:"calc(100% - 31PX)"}}>
                                        <Stack className='my-auto' direction="row" alignItems="start" spacing={1.5}>
                                            <img  src={tiles} alt="kiaLogo"/>
                                            <Box className='d-flex w-100 justify-content-between my-auto'>
                                                <Typography sx={{lineHeight:"normal !important"}} className={`${Style.cardTitle} fs-13 my-auto fw-300`} variant="p">
                                                    HR Johnson
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Card>
                                </Box>
                                <Box className="Vendors-side-panel-list-item">
                                    <Card className={`${Style?.homeCard1} Vendors-side-panel-list-item-card`} sx={{height:"calc(100% - 31PX)"}}>
                                        <Stack direction="row" alignItems="start" spacing={1.5}>
                                            <img  src={tiles} alt="kiaLogo"/>
                                            <Box className='d-flex w-100 justify-content-between my-auto'>
                                                <Typography sx={{lineHeight:"normal !important"}} className={`${Style.cardTitle} fs-13 my-auto fw-300`} variant="p">
                                                    Orient
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Card>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>

                        <Box className="Vendors-main-panel">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="flex-wrap justify-content-between">
                                        <Box className="flex-wrap gap-10">
                                            <Box>
                                                <img src={HRJohnson} />
                                            </Box>
                                            <Box className="my-auto">
                                                <Typography variant='h4' className='fs-13 fw-600'>HR Johnson</Typography>
                                                <Typography variant='h4' className='fs-12 fw-400'>Johnson Tiles to suit your functionality</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="my-auto">
                                            <Box className="my-auto">
                                                <Typography variant='h4' className='fs-13 fw-600 d-flex gap-10'><PhoneIcon className='fs-13 fw-600 my-auto'/>+91 9876543210</Typography>
                                                <Typography variant='h4' className='fs-12 fw-400 d-flex gap-10'><EmailIcon className='fs-13 fw-600 my-auto'/>hrjohnson@mail.com</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Divider orientation="horizontal" flexItem />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='gap-10 flex-wrap'>
                                    <Button className='layout-blue-btn'>Tiles</Button>
                                    <Button className='layout-lightblue-btn'>Lights</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TableContainer className="solidtable Vendor-table" sx={{ maxHeight: 440 }}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center" className='border-right'>Product Name</StyledTableCell>
                                                                    <StyledTableCell align="center" className='border-right'>Product Category</StyledTableCell>
                                                                    <StyledTableCell align="center" className='border-right'>Snap</StyledTableCell>
                                                                    <StyledTableCell align="center" className='border-right'>Qtn</StyledTableCell>
                                                                    <StyledTableCell align="center" className='border-right'>Price</StyledTableCell>
                                                                    <StyledTableCell align="center" className='border-right'>Unit</StyledTableCell>
                                                                    <StyledTableCell align="center" ></StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                                <TableBody>
                                                                        <TableRow>                                           
                                                                            <TableCell className='border-right'>
                                                                                Mosaic Tile (600 *600mm) (FF-01)
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                Showroom area within infinity loop and glass façade,  toilets & pantry
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                <img width={39} height={36} src={tiles}></img>
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                300
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                1,00,000
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                sqm
                                                                            </TableCell>
                                                                            <TableCell align="center" >
                                                                                <Button variant="contained" className='blue-btn fs-13' sx={{color:"#5E6063 !important"}} >Add</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>                                           
                                                                            <TableCell className='border-right'>
                                                                                Mosaic Tile (600 *600mm) (FF-01)
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                Showroom area within infinity loop and glass façade,  toilets & pantry
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                <img width={39} height={36} src={tiles}></img>
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                300
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                1,00,000
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                sqm
                                                                            </TableCell>
                                                                            <TableCell align="center" >
                                                                                <Button variant="contained" className='blue-btn fs-13' sx={{color:"#5E6063 !important"}} >Add</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>                                           
                                                                            <TableCell className='border-right'>
                                                                                Mosaic Tile (600 *600mm) (FF-01)
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                Showroom area within infinity loop and glass façade,  toilets & pantry
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                <img width={39} height={36} src={tiles}></img>
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                300
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                1,00,000
                                                                            </TableCell>
                                                                            <TableCell align="center" className='border-right'>
                                                                                sqm
                                                                            </TableCell>
                                                                            <TableCell align="center" >
                                                                                <Button variant="contained" className='blue-btn fs-13' sx={{color:"#5E6063 !important"}} >Add</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                </TableBody>
                                                            </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-end'>
                                    <Button className='black-btn'>Assigned</Button>

                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </Body>
        
    );
}
export default Assign
