import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextareaAutosize,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import ModalCss from "../../styles/modal.module.scss";
  import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
  import { useDispatch } from "react-redux";
  import TextSnippetIcon from "@mui/icons-material/TextSnippet";
  import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
  import { showToast } from "../../toast/toast";
  import { ordersApi, uploadInvoiceApi } from "../../store/orders/orders";
  import CancelIcon from '@mui/icons-material/Cancel';
  import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs

  
  const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
  }));
  
  const UploadOrderInvoiceModal = ({ open, handleClose, orderItems}) => {
    const classes = useStyles();
    const [fileError, setFileError] = useState("");
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [fileState, setFileState] = useState([]);
    const [invoiceAmount, setInvoiceAmount] = useState("");
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");

    const handleBulkUpload = () => {
      inputRef.current.click();
    };
  
    const handleFileUpload = (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = [];
      let errorMessage = "";
      selectedFiles.forEach((file) => {
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileType === 'application/pdf' && fileExtension === 'pdf') {
          validFiles.push({
            id: uuidv4(),
            file,
          });
        } else {
            errorMessage = "Only PDF files are allowed.";
        }
      });

      if (validFiles.length > 0) {
        setFiles(prevState => [...prevState, ...validFiles]);
        setFileState(prevState => [...prevState, ...validFiles]); 
        setFileError("");
      } else {
        setFiles([]);
        setFileState([]);
        setFileError(errorMessage);
      }

    };
  
    const handleDeleteFile = (fileId) => {
      setFiles((prevFiles) => prevFiles.filter(file => file.id !== fileId));
      setFileState((prevFiles) => prevFiles.filter(file => file.id !== fileId));
  };
  
    const sendInvoiceToDealer = async () => {
      
      if (!fileState) {
        // Handle the error if no file is selected
        showToast("Please upload an invoice",2);
        return;
      }
      if (fileState.length < 1) {
        showToast("Please upload an invoice", 2);
        return;
    }

      if(!invoiceAmount){
        showToast("Please enter invoice amount",2);
        return;
      }

      const regex = /^\d{1,10}(\.\d{1,2})?$/;
      if (!regex.test(invoiceAmount)) {
        showToast("Please enter a valid invoice amount (up to 10 digits, with one decimal point)", 2);
        return;
    }

      if(!selectedItem){
        showToast("Please select an order item",2);
        return;
      }
  
      const formData = new FormData();
      formData.append("sub_order_id", selectedItem);
      fileState.forEach((file, index) => {
        formData.append(`upload_invoice`, file.file);
      });
      formData.append("invoice_amount", invoiceAmount)

        try {
          setLoading(true);
          await dispatch(uploadInvoiceApi(formData))
            .then((data) => {
              
              if(data?.error){
                setLoading(false);
                showToast(data?.payload?.error, 2);
                return;
              }

              showToast(data?.payload?.message, 1);
              setLoading(false);
              dispatch(ordersApi());
              setTimeout(() => {
                  
                  closeTheModal()
              },1000)            
              })
      
            .catch((error) => {
              showToast(error, 2);
              console.error("Error sending invoice:", error);
            });
        } catch (error) {
          setLoading(false);
          showToast(error, 2);
          console.error("Error sending invoice:", error);
        }     
    };

    const closeTheModal = () => {
      setSelectedItem("");
      setFiles([]);
      setFileError("");
      setFileState("");
      setInvoiceAmount("");
      handleClose();
    };
    
    
    return (
      <Modal open={open} onClose={() => closeTheModal()} className={ModalCss.customModal}>
        <Card className={`modal ${ModalCss.customModal_md}`}>
          <CardHeader sx={{ textAlign: "center",margin:0 }} title="Upload Invoice" />
          <Typography component="div" sx={{ textAlign: "center", marginTop: 0, marginBottom: 1 }}>
            Please upload your invoice and send it to the dealer.
          </Typography>
          <Box
            component="div"
            sx={{
              maxWidth: "600px",
              margin: "0 auto",
              textAlign: "center",
              padding: "20px",
              maxHeight:"400px",overflowY:"scroll"
            }}
          >
            <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
              <IconButton onClick={() => closeTheModal()}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack
              justifyContent="center"
              direction="column"
              gap={2}
              alignItems="center"
              fontSize={"18px"}
              boxSizing={"border-box"}
              component={"div"}
            >
              {/* Dropdown added here */}
              <Box
                component={"div"}
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                marginBottom={"0px"}
              >
                <Typography variant="h6" sx={{ fontSize: "17px", fontWeight: 400 }}>Select Item</Typography>
                <FormControl fullWidth style={{ marginTop: "0px" }}>
                  <InputLabel id="invoice-type-label">Item</InputLabel>
                  <Select
                    labelId="invoice-type-label"
                    id="invoice-type"
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.target.value)}
                    label="Item"
                  >
                  {Array.isArray(orderItems) && orderItems.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.category} - {item.supplier}
                    </MenuItem>
                  ))}
                    
                  </Select>
                </FormControl>
              </Box>

              <Box
                component={"div"}
                className={`${classes.bgLightGray} ${classes.borderRadius10} ${classes.p20} ${classes.fullWidth}`}
                boxSizing={"border-box"}
                sx={{
                  backgroundColor: "#e0e0e0",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"div"}
                  sx={{
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    lineHeight: 1.2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {files.length > 0 ? (
                    <>
                      <Box sx={{ height: "fit-content" }}>
                        {files.map(file => (
                          <>
                            <Typography key={file.name} sx={{ fontSize: "15px" }}>
                              {file.file.name}
                            </Typography>
                            <IconButton 
                              className={classes.deleteButton}
                              onClick={() => handleDeleteFile(file.id)}
                            >
                              <CancelIcon />
                            </IconButton>
                          </>
                        ))}
                      </Box>
                    </>
                  ) : (
                    <>
                      <FileUploadOutlinedIcon sx={{ fontSize: "80px" }} />
                      Upload Invoice(s)
                    </>
                  )}
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept="application/pdf"
                  />
                </Typography>
              </Box>
              {fileError && (
                <Typography
                  component={"div"}
                  sx={{ color: "red", fontSize: "14px", marginTop: 1 }}
                >
                  {fileError}
                </Typography>
              )}
              <Box
                component={"div"}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"10px"}
              >
                <Button
                  variant="contained"
                  onClick={handleBulkUpload}
                  multiple
                  className="white-btn"
                >
                  Choose file
                </Button>
              </Box>
              <Box
                component={"div"}
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                marginTop={"0px"}
              >
                <Typography variant="h6" sx={{ fontSize: "17px", fontWeight: 400 }}>Invoice Amount</Typography>
                <TextField
                  style={{ width: "100%", borderRadius: "5px", marginTop: "10px" }}
                  className="bg-white"
                  value={invoiceAmount}
                  type="number"
                  inputProps={{length: 10}}
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                />
              </Box>
              <Divider sx={{ width: "100%" }}></Divider>
              <Box
                component={"div"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginTop={"20px"}
              >
                <Button onClick={closeTheModal} sx={{ textDecoration: "underline", color: "#000000" }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={sendInvoiceToDealer}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: 1.8,
                    boxShadow: "none",
                    backgroundColor: "#000000",
                  }}
                >
                  {loading ? <CircularProgress variant="indeterminate" size={40} /> : "Upload"}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Modal>

  );
  };
  
  export default UploadOrderInvoiceModal;
  