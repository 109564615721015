import React, { useEffect, useState } from "react";
import {useMediaQuery} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutApi } from "../store/auth/logout";
import { useDispatch, useSelector } from "react-redux";
import { isHandleDrawer } from "../store/drawer";
import { MobileDrawer } from "./MobileDrawer";
import WebDrawer from "./WebDrawer";
import Style from "../styles/drawer.module.scss";
import { resetVerifyAuthOtp } from "../store/auth/verifyAuthOtp";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
  menuUnstyledList: {
    listStyle: "none",
    listStylePosition: "inside",
    marginInlineStart: 20,
    marginBlock: 0,
    paddingBottom: 10,
    "& li": {
      padding: "8px 0",
      "& a": {
        opacity: 0.5,
        fontSize: 14,
      textDecoration: "none",
        color: "#fff",
        "&:hover": {
          opacity: 1,

        },
      },
      "&.active a": {
        opacity: 1,
        textDecoration: "none"
      },
    },
  },
});

export default function Drawer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const {result:isHamburgerOpen}= useSelector((state) => state.drawerTypeReducer);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
    if (isExpanded) {
      localStorage.setItem("expandedAccordion", panel);
    } else {
      localStorage.removeItem("expandedAccordion");
    }
  };

  const closeHamburger = () => {
    dispatch(isHandleDrawer(!isHamburgerOpen))
  }
  const handleLogout = async () => {
    const newState = false;
    dispatch(isHandleDrawer(newState))
    dispatch(logoutApi())
    dispatch(resetVerifyAuthOtp())
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const segments = pathname.split("/").filter(Boolean);
    const firstSegment = segments[0];
    let accordionToExpand = null;
    switch (firstSegment) {
      case "layout":
        accordionToExpand = "projectMasteringAccordion";
        break;
      case "order":
        accordionToExpand = "orderMasteringAccordion";
        break;
      case "inventory":
        accordionToExpand = "inventoryManagementAccordion";
        break;
      case "user":
        accordionToExpand = "profileAccordion";
        break;
      default:
        accordionToExpand = "homeAccordion";
        break;
    }
    setExpandedAccordion(accordionToExpand);
    localStorage.setItem("expandedAccordion", accordionToExpand);
  }, [pathname]);

  useEffect(() => {
    const savedAccordion = localStorage.getItem("expandedAccordion");
    if (savedAccordion) {
      setExpandedAccordion(savedAccordion);
    }
  }, []);

  return (
    <>
    {isMobile ? 
      (<MobileDrawer isHamburgerOpen ={isHamburgerOpen} Style={Style} closeHamburger={closeHamburger} expandedAccordion={expandedAccordion}  handleAccordionChange={handleAccordionChange} navigate={navigate} handleLogout={handleLogout} classes={classes}/>)
      : 
      (<WebDrawer Style={Style} closeHamburger={closeHamburger} expandedAccordion={expandedAccordion}  handleAccordionChange={handleAccordionChange} navigate={navigate} handleLogout={handleLogout} classes={classes}/>)
    }
    </>
  );
}
