export const truncateText =(text, maxLength)  =>{
    if (text.length <= maxLength) {
      return text;
    }
  
    // Find the last space within the maxLength to avoid cutting off a word
    const truncatedText = text.substring(0, maxLength).trim();
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
  
    // If there's no space, cut at maxLength
    const finalText = lastSpaceIndex > 0 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText;
  
    return finalText + '...';
  }
  