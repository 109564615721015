import { Button, Card, FormControl, FormControlLabel, FormLabel, IconButton, Modal, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function OrderLocation({ open, setOpen }) {

  const handleClose = () => setOpen(false)

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className='modal'>
          <Stack spacing={2}>
            <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>You are ordering for</Typography>
              <Typography>You are ordering</Typography>
            </Stack>

            <FormControl>
              <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label">
                <Stack spacing={2}>
                  <Card className='card1'><FormControlLabel value="female" control={<Radio />} label="Amit KIA Bengaluru" /></Card>
                  <Card className='card1'><FormControlLabel value="male" control={<Radio />} label="Amit KIA Lucknow" /></Card>
                  <Card className='card1'><FormControlLabel value="other" control={<Radio />} label="Amit KIA Dehradun" /></Card>
                </Stack>
              </RadioGroup>
            </FormControl>
            <Button variant='contained'>Continue</Button>
          </Stack>
        </Card>
      </Modal>
    </>
  )
}
