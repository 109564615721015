export const calculateTotal = (orderItems) => {
  return parseFloat(orderItems.reduce((total, item) => {
    if (item.quantity && item.price) {
      return total + (item.quantity * item.price);
    }
    return total;
  }, 0).toFixed(2));
};

export const  fixedPriceToDecimal =(x, upto)=> {
  return Number.parseFloat(x).toFixed(upto);
}