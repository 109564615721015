import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const resetPasswordVerifyOtpApi = createAsyncThunk(
    'auth/reset-password-verify-otp',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/login/reset_password/`, data,
                { 
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const resetPasswordVerifyOtpSlice = createSlice({
    name: 'reset-password-verify-otp',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(resetPasswordVerifyOtpApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(resetPasswordVerifyOtpApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(resetPasswordVerifyOtpApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const resetPasswordVerifyOtpReducer = resetPasswordVerifyOtpSlice.reducer