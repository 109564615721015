import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import Home from "../view/home";
import BillsAndInvoices from "../view/orders/BillsAndInvoices";
import DispatchStatus from "../view/orders/DispatchStatus";
import PurchaseOrders from "../view/orders/PurchaseOrders";
import YourOrder from "../view/orders/YourOrder";
import ProtectedRoute from "./ProtectedRoute";
import Layout from "../view/home/Layout";
import LayoutTable from "../view/home/LayoutTable";
import ProtectedLayout from "../components/ProtectedLayout";
import Inventory from "../view/InventoryManagement";
import Order from "../view/OrderManagement";
import OrderValidationHistory from "../view/OrderManagement/OrderValidationHistory";
import View from "../view/OrderManagement/view";
import Assign from "../view/OrderManagement/assign";
import OrderReciept from "../view/OrderManagement/OrderReciept";
import OrderReceipt from "../view/OrderManagement/OrderReciept";
import OrderStatus from "../view/OrderManagement/OrderStatus";
import MyProfile from "../view/InnProfile/UserProfile";
import OrderDispatchStatus from "../view/OrderManagement/OrderDispatchStatus";

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />

        <Route path="/" element={<ProtectedLayout />}>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/orders/purchase" element={<ProtectedRoute><PurchaseOrders /></ProtectedRoute>} />  
          <Route path="/user/profile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>}/>
          <Route path="/orders/bills-invoices" element={<ProtectedRoute><BillsAndInvoices /></ProtectedRoute>} />
                      
          <Route path="/orders/created" element={<ProtectedRoute><YourOrder /></ProtectedRoute>} />
          <Route path="/layout/view" element={<ProtectedRoute><Layout/></ProtectedRoute>} />
          <Route path="/layout" element={<ProtectedRoute><LayoutTable/></ProtectedRoute>} />
          <Route path="/inventory" element={<ProtectedRoute><Inventory/></ProtectedRoute>} />
          <Route path="/order" element={<ProtectedRoute><Order/></ProtectedRoute>} />
          <Route path="/order/view" element={<ProtectedRoute><View/></ProtectedRoute>} />
          <Route path="/order/receipt" element={<ProtectedRoute><OrderReceipt/></ProtectedRoute>} />
          <Route path="/order/dispatch-status" element={<ProtectedRoute><OrderDispatchStatus /></ProtectedRoute>} />  
          <Route path="/order/status" element={<ProtectedRoute><OrderStatus/></ProtectedRoute>} />
          <Route path="/order/assign" element={<ProtectedRoute><Assign/></ProtectedRoute>} />
          <Route path="/order/history" element={<ProtectedRoute><OrderValidationHistory/></ProtectedRoute>} />
        </Route>
        
                
      </Routes>
    </BrowserRouter>
  );
}
