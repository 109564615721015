import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const currentUserApi = createAsyncThunk(
    'auth/current-user',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/v1/api/user/current`, { params: { token: data } })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const currentUserSlice = createSlice({
    name: 'current-user',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(currentUserApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(currentUserApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(currentUserApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const currentUserReducer = currentUserSlice.reducer