import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const addApplicantApi = createAsyncThunk(
    'onBoarding/add-applicant',
    async ( formData, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/super_admin/applicants/`,formData,          
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const editApplicantApi = createAsyncThunk(
    'onBoarding/edit-applicant',
    async ( formData, { rejectWithValue, getState }) => {
        try {
            const email = formData.get('email');
            formData.delete('email');
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.patch(`/api/v1/super_admin/applicants/?email=${email}`,formData,          
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const sendInvites = createAsyncThunk(
    'onBoarding/send-invites',
    async ( formData, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/super_admin/send-invites/`,formData,          
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const deleteApplicantApi = createAsyncThunk(
    'onBoarding/delete-applicant',
    async (email, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.delete(`/api/v1/super_admin/applicants/?email=${email}`,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const addApplicantSlice = createSlice({
    name: 'add-applicant',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(addApplicantApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addApplicantApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(addApplicantApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })


        // Add reducer cases for editApplicantApi
        builder.addCase(editApplicantApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(editApplicantApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(editApplicantApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });


        // Add reducer cases for deleteApplicantApi
        builder.addCase(deleteApplicantApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteApplicantApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(deleteApplicantApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });

        // Add reducer cases for sendInvite
        builder.addCase(sendInvites.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendInvites.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(sendInvites.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });
    },
})

export const addApplicantReducer = addApplicantSlice.reducer


