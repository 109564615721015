import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createTheme,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../toast/toast";
import { addRemarkSchema } from "../validationSchemas";
import { ordersApi, remarkOrderApi } from "../../store/orders/orders.js";
import fieldsStyle from "../../styles/field.module.scss";
import style from "../../styles/buttons.module.scss";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from '@mui/icons-material/Cancel';
import { Cancel } from "@mui/icons-material";

const AssignOrder = ({ open, handleClose, subOrderId, supplierId }) => {
const dispatch = useDispatch();
const navigate = useNavigate();
const [errors, setErrors] = useState({});
const inputRef = useRef(null);
const [inputValues, setInputValues] = useState();
const [selectedSupplierName, setSelectedSupplierName] = useState('')
const [body, setBody] = useState()
const [subject, setSubject] = useState();
const [attachment, setAttachment] = useState([]);
const [fileState, setFileState] = useState([]);

const theme = createTheme({
palette: {
  secondary: {
    main: "#D3E1EF",
  },
},
});


const resetForm = () => {
  setSubject('')
  setBody('')
  setAttachment([])
  setFileState([])
};

const submitRemarkDetails = (e) => {
  e.preventDefault();
   addRemarkSchema.validate(inputValues, { abortEarly: false })
      .then(() => {
          addRequestAPI();
          setErrors({});
      })
      .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
              newErrors[error.path] = error.message;
          });
          setErrors(newErrors);
      });
};

const addRequestAPI = async () => {
  try {
      const dataToBeSent = new FormData();
      dataToBeSent.append('sub_order_id', subOrderId);
      dataToBeSent.append('remark', inputValues?.remark);
      const response = await dispatch(remarkOrderApi(dataToBeSent));
      showToast('Remark added successfully', 1)
      dispatch(ordersApi())
      resetForm();
      handleClose();
  } catch (error) {
      showToast(error, 2);
  }
  
}


const handleCancel = () => {
  resetForm();
  handleClose();
};

const handleAddAttachment = () => {
    inputRef.current.click();
}



const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFileState([...fileState, ...files]);
  };

  const handleDeleteFileState = (fileName) => {
    setFileState(fileState.filter(file => file.name !== fileName));
  };

const deleteAttachment = (attachmentId) => {
    const formData = new FormData();
    formData.append("attachments_to_remove", attachmentId);
    //const payload = { editTemplateId: id, formData };
    // dispatch(handleDeleteAttachment(payload))
    //   .then((data) => {
    //     showToast("Attachment deleted successfully", 1);
    //     setAttachment((prev) => prev.filter((file) => file.id !== attachmentId));
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting attachment:", error);
    //   });
  };

const options = [
    { label: 'VVT', value: 'vvt'  },
    { label: 'ValueCoders', value: 'valuecoders'},
    { label: 'Johnson', value: 'johnson' },
];

return (
    <Modal
        open={open}
        onClose={handleCancel}
        className={ModalCss?.customModal}
        BackdropProps={{
            style: { pointerEvents: "none" }, // Disable click events on the backdrop
        }}
       
        sx={{
    '& .MuiModal-paper': {
      width: '100%',  // Adjust width as needed
      maxWidth: '1200px',  // Set a maximum width
      height: '80%',  // Adjust height as needed
      maxHeight: '800px',  // Set a maximum height
      margin: 'auto',
      borderRadius: '8px',
      padding: 2
    },
  }}
    >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
            <CardHeader
                className="popup-header-rev"
                action={
                <IconButton onClick={handleCancel}>
                    <HighlightOffIcon />
                </IconButton>
                }
                title={
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Typography variant="h6">Assign Order</Typography>
                </Box>
                }
            />
            <Divider />

            <Box sx={{ mt: 2, mb: 2 }}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Option"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    onChange={(event, newValue) => {
                        if(newValue?.label){
                            setSelectedSupplierName(newValue?.label)
                            setSubject("Hello HR "+ newValue?.label +",")
                        }else{
                            setSelectedSupplierName()
                            setSubject("")
                        }
                        
                        // Handle selection
                    }}
                />
            </Box>

            <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <Card
                variant="outlined"
                sx={{
                    borderColor: "#C9E2FB",
                    boxShadow: " 0px 4px 4px 2px #DEECFB",
                    borderRadius: "10px",
                    mt: 5,
                }}
                >
                <CardContent sx={{ p: 3 }}>
                    <Typography component="div" sx={{ textAlign: "left" }}>
                        <textarea className={fieldsStyle.prevEditTextarea}
                            variant="body2"
                            sx={{ my: 3, fontSize: 13, fontWeight: 500, lineHeight: 1.5 }}
                            value={subject} 
                            autoFocus={false}
                            placeholder="Enter Subject"
                            onChange={(e) => setSubject(e.target.value)}
                        >
                        </textarea>
                        <textarea className={fieldsStyle.prevEditTextarea} id={fieldsStyle.previewBody}
                            variant="body2"
                            sx={{ my: 3, fontSize: 13, fontWeight: 500, lineHeight: 1.5 }}
                            value={body}
                            placeholder="Enter Body"
                            onChange={((e) => setBody(e.target.value))}
                        >
                        </textarea>
                    </Typography>
                </CardContent>
                </Card>

                {attachment?.map((file) => (
                <Typography key={file.id} className={style.attachmentBtn}>
                    {file?.file?.split('/').pop()}
                    <CancelIcon onClick={() => deleteAttachment(file.id)} />
                </Typography>
                ))}

                {Array.isArray(fileState) && fileState.map((item, index) => (
                <Typography key={index} className={style.attachmentBtn}>
                    {item.name}
                    <CancelIcon onClick={() => handleDeleteFileState(item.name)} />
                </Typography>
                ))}

                <Box component={"div"}>
                    <Grid container py={6}>
                        <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        gap={2}
                        sx={{ display: "flex", justifyContent: "start", gap: 2 }}
                        >
                            <ThemeProvider theme={theme}>
                                <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    py: 2,
                                    px: 4,
                                    color: "#68798A",
                                    //fontSize: "16px",
                                    //fontWeight: "600",
                                    boxShadow: "none",
                                    "&:hover": {
                                    backgroundColor: "#68798A",
                                    color: "#fff",
                                    },
                                }}
                                onClick={handleCancel}
                                >
                                Back
                                </Button>
                            </ThemeProvider>
                            <ThemeProvider theme={theme}>
                                <Button
                                variant="contained"
                                // style="black-btn"
                                // sx={{
                                //     py: 2,
                                //     px: 4,
                                //     // fontSize: "16px",
                                //     // fontWeight: "600",
                                //     boxShadow: "none",
                                // }}
                                startIcon={<AttachFileIcon></AttachFileIcon>}
                                //onClick={handleAttchmetModal}
                                >
                                GFC Attached
                                </Button>
                                
                            </ThemeProvider>
                            <Typography
                                component="div"
                                sx={{ mt: 2, cursor: 'pointer', color: '#007BFF' }}
                                onClick={() => document.getElementById('file-input').click()}
                                >
                                Add more attachment
                                </Typography>
                                <input
                                id="file-input"
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                />
                
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            gap={2}
                            sx={{ display: "flex", justifyContent: "end", gap: 2 }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    py: 2,
                                    px: 4,
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    boxShadow: "none",
                                }}
                                onClick={() => {
                                //handleEditTemplate();
                                }}
                            >
                                Send
                            </Button>
                    
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
  </Modal>
  
  );
};

export default AssignOrder;
