import { toast } from "react-hot-toast";
import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {}
});


instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    // toast.success(response?.data?.message, {})
    return response;
}, function (error) {
    // toast.error(error?.response?.data?.message, { position: 'top-right' })
    return Promise.reject(error);
});

export default instance