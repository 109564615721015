import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import PendingOrdersIcon from '../../assets/svg/pending_orders.svg'
import box from '../../assets/svg/box.svg';
import trackOrderIcon from '../../assets/svg/Track_Order_Status.svg';
import paymentIcon from '../../assets/svg/Payments.svg';
import Style from "../../styles/home.module.scss";
import SchematicLayout from '../../assets/img/SchematicLayouticon.png'
import { useDispatch, useSelector } from "react-redux";
import { ordersApi } from "../../store/orders/orders";


const DashboardOrderBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders);


    useEffect(() => {
      dispatch(ordersApi())
    },[])
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{boxShadow: "0px 4px 24px 0px #E0F0FF !important"}}>            
                  <Box className={Style?.headerSectionWhite}>
                    <Typography className={Style?.cardHeadingWhite} variant="h6">
                      Order Management
                    </Typography>
                  </Box>

                  <Stack sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={Style?.homeCard2} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate("/order")} style={{cursor:"pointer"}}>
                          <Stack direction="row" alignItems="center" spacing={1.5}>
                            <img src={box} alt="Total Orders" />
                            <Stack>
                              <Typography className={Style.cardTitle} variant="p">
                                Total Orders
                              </Typography>
                              <Typography className={Style.cardValue} variant="h5">
                                {orderList?.data?.length}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={Style?.homeCard3} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate("/order")} style={{cursor:"pointer"}}>
                          <Stack direction="row" alignItems="center" spacing={1.5}>
                            <img src={PendingOrdersIcon} alt="Pending Order"/>
                            <Stack>
                              <Typography variant="p"  className={Style.cardTitle}>
                                Pending Order
                              </Typography>
                              <Typography variant="h5"  className={Style.cardValue}>
                                0
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={Style?.homeCard4} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate("/order")} style={{cursor:"pointer"}}>
                          <Stack direction="row" alignItems="center" spacing={1.5}>
                            <img src={trackOrderIcon} alt="Track Order Status"/>
                            <Stack>
                              <Typography variant="p" className={Style.cardTitle}>
                                Track Order Status
                              </Typography>
                              <Typography variant="h5" className={Style.cardValue}>
                                0
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={Style?.homeCard1} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate("/order")} style={{cursor:"pointer"}}>
                          <Stack direction="row" alignItems="center" spacing={1.5}>
                            <img src={paymentIcon} alt="Payments"/>
                            <Stack>
                              <Typography variant="p" className={Style.cardTitle}>
                                Payments
                              </Typography>
                              <Typography variant="h5" className={Style.cardValue}>
                                0
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>      
              </Grid>
        </>
    )

}   

export default DashboardOrderBlock;