import React, { useEffect, useState } from "react"
import Body from '../../components/Body'
import { Box, Button, Card, CardActions, CardContent, CardHeader,  Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow,  Typography } from "@mui/material"
import meter from '../../assets/img/meter.png'
import { styled } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { ordersApi } from "../../store/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import { formatDateString } from "../../utils/dateFormater";
import UploadOrderInvoiceModal from "../reuseable/UploadOrderInvoiceModal";
import { handleDownload } from "../reuseable/downloadFile";
import ViewPaymentModal from "../reuseable/ViewPaymentModal";
import ViewUploadedQuotationModal from "../reuseable/ViewUploadedQuotationModal";
import ViewUploadedOrderInvoiceModal from "../reuseable/ViewUploadedOrderInvoiceModal";
import { calculateTotal } from "../../utils/priceManipulation";
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
function OrderReceipt() {
    const dispatch = useDispatch();
    const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders); 

    const [orderId, setOrderId] = useState()
    const [itemList, setItemList] = useState([])
    const [open, setOpen] = useState(false);  // State for modal visibility
    const handleOpen = (id) => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleUploadInovice = (items, invoices) => {
        const filteredItems = items.filter(
            (item) => !invoices.some((invoice) => invoice.sub_order_id === item.id)
          );
        setItemList(filteredItems)
        setOpen(true)
    }



    const [openView, setOpenView] = useState(false);  // State for modal visibility
    const handleOpenView = (id) => setOpenView(true);
    const handleCloseView = () => {
        setOpenView(false);
        setPaymentFile(null)
    }

    const [paymentFile, setPaymentFile] = useState(null);
    const handleViewPayment = (file) => {
        setPaymentFile(file)
        setOpenView(true)
    }
    useEffect(() => {
        dispatch(ordersApi())
    },[dispatch])
    
    const [quotationArray, setQuotationArray] = useState([]);
    const [openQuotationModal, handleOpenQuotationModal] = useState(false);
    const handleQuotationView = (orderQuotationArray) => { 
        setQuotationArray(orderQuotationArray);
        handleOpenQuotationModal(true)
    } 

    const handleCloseQuotationModal = () => {
        handleOpenQuotationModal(false)
        setQuotationArray([])
    }

    const [invoiceArray, setInvoiceArray] = useState([]);
    const [openInvoiceModal, handleOpenInvoiceModal] = useState(false);
    const handleInvoiceView = (orderInvoiceArray) => { 
        setInvoiceArray(orderInvoiceArray);
        handleOpenInvoiceModal(true)
    } 

    const handleCloseInvoiceModal = () => {
        handleOpenInvoiceModal(false)
        setInvoiceArray([])
    }
    return (
        <Body>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className='d-flex' sx={{paddingBlock:"0px !important",background:"none !important",boxShadow:"none !important"}}>
                        <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                            <img style={{marginBottom:"5px !important"}} src={meter} alt="meterIcon"/>
                            <Typography sx={{lineHeight:"normal !important"}} >View Order Receipt</Typography>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
            {orderList && orderList.data && orderList.data.length > 0 ? (
                orderList.data.map((item) => (
                <>
                    <Grid container spacing={1} mb={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card className="order-card">
                                <CardHeader
                                    avatar={
                                        <Box sx={{ borderRight: "1px solid #D4E0EC !important",paddingRight: "10px"}}>
                                            <Typography variant="h5" className="fs-15 fw-400">{item?.ordered_by_user_name}</Typography>
                                            <Typography variant="h5" className="fs-15 fw-400">{item?.cluster_name}</Typography>
                                        </Box>
                                    }
                                    title="Main Order ID"
                                    subheader={item?.main_order_id}
                                />
                                <CardContent>
                                    <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow:"none !important" }}>
                                        <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="cstm-tablecell1">Order Date</TableCell>
                                                        <TableCell className="cstm-tablecell2">{formatDateString(item?.order_date)}</TableCell>
                                                        <TableCell className="cstm-tablecell1">Order Total</TableCell>
                                                        <TableCell className="cstm-tablecell2"> ₹ {calculateTotal(item?.order_items)} ({item?.order_items?.length} Material)</TableCell>
                                                    </TableRow>
                                                     <TableRow>
                                                        {/* <TableCell className="cstm-tablecell1">ETA</TableCell>
                                                        <TableCell className="cstm-tablecell2"></TableCell> */}
                                                        <TableCell className="cstm-tablecell1">Payment</TableCell>
                                                        <TableCell className="cstm-tablecell2">{(item.payments).length > 0 ? "Completed" : "Not Completed"}</TableCell>
                                                        <TableCell className="cstm-tablecell1">Payment on</TableCell>
                                                        <TableCell className="cstm-tablecell2">{(item.payments).length > 0 ? formatDateString(item.payments[0]?.payment_on) : ""}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        
                                                        <TableCell className="cstm-tablecell1">Amount Paid</TableCell>
                                                        <TableCell className="cstm-tablecell2">{item.payments[0]?.amount_paid}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        
                                                        {/* <TableCell className="cstm-tablecell1">Delivery Contact Name</TableCell>
                                                        <TableCell className="cstm-tablecell2">-</TableCell> */}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                        {/* {item.invoices?.length > 0 ? (
                                            <Button disabled={item.invoices?.length > 0 ? false : true} onClick={ () => handleInvoiceView(item.invoices) } className={item.invoices?.length > 0 ? "success-btn" : "white-btn"} sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                            View Invoice
                                            </Button> 
                                        ) : (
                                            <Button disabled={item.invoices?.length} onClick={ () => handleUploadInovice(item.order_items) } className="black-btn" sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                                {!item.invoices?.length ? <><UploadIcon />Upload Invoice</> : "Invoice Uploaded"}
                                            </Button>
                                            
                                        )} */}


                                        {item.order_items?.length === item.invoices?.length ? (
                                            <Button 
                                                disabled={item.invoices?.length === 0} 
                                                onClick={() => handleInvoiceView(item.invoices)} 
                                                className={item.invoices?.length > 0 ? "success-btn" : "white-btn"} 
                                                sx={{
                                                    padding: "10px 20px 10px 20px !important",
                                                    minWidth: "110px !important",
                                                    fontSize: "13px !important",
                                                    gap: "5px",
                                                    textTransform: "capitalize"
                                                }}>
                                                View Invoice
                                            </Button>
                                        ) : (
                                            <Button 
                                                //disabled={item.invoices?.length > 0} 
                                                onClick={() => handleUploadInovice(item.order_items, item?.invoices)} 
                                                className="black-btn" 
                                                sx={{
                                                    padding: "10px 20px 10px 20px !important",
                                                    minWidth: "110px !important",
                                                    fontSize: "13px !important",
                                                    gap: "5px",
                                                    textTransform: "capitalize"
                                                }}>
                                                <><UploadIcon /> Upload Invoice</>
                                            </Button>
                                        )}




                                        {/* <Button disabled={item.invoices?.length} onClick={ () => handleUploadInovice(item.id) } className="black-btn" sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                                {!item.invoices?.length ? <><UploadIcon />Upload Invoice</> : "Invoice Uploaded"}
                                            </Button> */}
                                        <Button disabled={item.order_quotations?.length > 0 ? false : true} onClick={ () => handleQuotationView(item.order_quotations) } className={item.order_quotations?.length > 0 ? "success-btn" : "white-btn"} sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                            {item.order_quotations?.length > 0 ? "View Quotation" : "Quotation not uploaded"}
                                        </Button>


                                        <Stack direction="row" spacing={2}>
                                            <Button disabled={!item?.payments?.[0]?.upload_receipt} onClick={ () => handleViewPayment(item?.payments?.[0]?.upload_receipt) } className={item?.payments?.[0]?.upload_receipt  ? "success-btn" : "white-btn"} sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                                View Payment Receipt
                                            </Button>
                                            <Button disabled={!item?.payments?.[0]?.upload_receipt} onClick={() => handleDownload(new Date() + 'payment_receipt', item?.payments?.[0]?.upload_receipt)} className="black-btn" sx={{padding:"10px 20px 10px 20px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                                <DownloadIcon/> Download Payment Receipt
                                            </Button>
                                        </Stack>
                                    </Box>
                                </CardActions>
                                {/* {item?.order_items?.length > 0 &&  isShowItems && (
                                    item?.orderItems?.map((item) => (
                                        <Box key={item.id} item={item} >
                                            <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', mt: 2 }}>
                                                {item?.name}
                                            </Typography>
                                            <Button>Upload Invoice</Button>
                                        
                                        </Box>
                                    ))
                                )} */}
                                
                            </Card>
                        </Grid>
                    </Grid>
                </>
            ))) : (
                    <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', mt: 2 }}>
                        No Orders Found
                    </Typography>
                )} 
           
            <UploadOrderInvoiceModal open={open} handleClose={handleClose} orderItems={itemList} />
            <ViewPaymentModal openView={openView} handleCloseView={handleCloseView} file={paymentFile} />
            <ViewUploadedQuotationModal openQuotation={openQuotationModal} handleCloseQuotation={handleCloseQuotationModal} quotation={quotationArray} />
            <ViewUploadedOrderInvoiceModal openInvoice={openInvoiceModal} handleCloseInvoice={handleCloseInvoiceModal} invoice={invoiceArray} />
        </Body>

    )  
}

export default OrderReceipt