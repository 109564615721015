import InventoryIcon from '@mui/icons-material/Inventory'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import { productListApi } from '../../store/products/productList'
import OrderLocation from '../auth/OrderLocation'


export default function PurchaseOrders() {

    const productListSelector = useSelector(state => state?.productList)
    const { result, loading } = productListSelector

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [orderLocation, setOrderLocation] = useState(false)

    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            width: 150
        },
        {
            field: 'supplier_name',
            headerName: 'Supplier Name',
            width: 300
        },
        {
            field: 'product_list',
            headerName: 'Product List',
            width: 500,
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 300
        },

    ]

    const handleCreateOrder = () => {
        navigate("/orders/created")
    }


    useEffect(() => {
        dispatch(productListApi())
    }, [dispatch])

    useEffect(() => {
        setOrderLocation(true)
    }, [])


    return (
        <>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>Welcome Amit Kia Noida</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>Order Status</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid                    
                        getRowId={(row) => row.category}
                        rows={result}
                        columns={columns}
                        loading={loading}
                        checkboxSelection
                        disableRowSelectionOnClick
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
                <Stack sx={{ mt: 2 }} alignItems='flex-end'>
                    <Button onClick={handleCreateOrder} variant='contained'>Create Order</Button>
                </Stack>
            </Body>


            <OrderLocation open={orderLocation} setOpen={setOrderLocation} />
        </>
    )
}
