import InventoryIcon from '@mui/icons-material/Inventory'
import { Box, Button, Card, IconButton, Paper, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import { productListApi } from '../../store/products/productList'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import OrderPlacedConf from './OrderPlacedConf'
import DeletePlacedProductConf from './DeletePlacedProductConf'



export default function YourOrder() {

    const productListSelector = useSelector(state => state?.productList)
    const { result, loading } = productListSelector

    const dispatch = useDispatch()

    const [orderPlaced, setOrderPlaced] = useState(false)
    const [delOrderItm, setDelOrderItm] = useState(false)

    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 150
        },
        {
            field: 'supplier_name',
            headerName: 'Supplier Name',
            minWidth: 200,
        },
        {
            field: 'product',
            headerName: 'Product',
            minWidth: 300,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            minWidth: 250,
            renderCell: () => (
                <>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Card className='card3'>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <IconButton className='btn2' size='small'><RemoveIcon variant='round' /></IconButton>
                                <Typography>1</Typography>
                                <IconButton className='btn2' size='small'><AddIcon /></IconButton>
                            </Stack>
                        </Card>
                        <Button size='small' className='btn1'>Update</Button>
                    </Stack>
                </>

            )
        },
        {
            field: 'price',
            headerName: 'Price',
            minWidth: 100
        },
        {
            field: 'delete',
            headerName: '',
            minWidth: 100,
            renderCell: () => (
                <IconButton onClick={() => setDelOrderItm(true)}><DeleteIcon /></IconButton>
            )
        },
    ]

    const handlePlaceOrder = () => {
        setOrderPlaced(true)
    }


    useEffect(() => {
        dispatch(productListApi())
    }, [dispatch])


    return (
        <>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>Welcome Amit Kia Noida</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>Order Status</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid
                        getRowId={(row) => row.category}
                        rows={result}
                        columns={columns}
                        loading={loading}
                        disableRowSelectionOnClick
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
                <Stack sx={{ mt: 2 }} direction='row' justifyContent='space-between'>
                    <Button variant='outlined'>ADD More Product</Button>
                    <Stack direction='row' spacing={1}>
                        <Button >Cancel</Button>
                        <Button variant='contained' onClick={handlePlaceOrder}>Place Order</Button>
                    </Stack>
                </Stack>
            </Body>

            <OrderPlacedConf open={orderPlaced} setOpen={setOrderPlaced} />
            <DeletePlacedProductConf open={delOrderItm} setOpen={setDelOrderItm} />
        </>
    )
}
