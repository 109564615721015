import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import OTPEmailAuth from "./OTPEmailAuth";
import OTPSMSAuth from "./OTPSMSAuth";
import { useDispatch, useSelector } from "react-redux";
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import { authOtpViaSMSApi } from "../../store/auth/authOtpViaSMS";
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from "../../toast/toast";
import closeLetterIconSvg from '../../assets/svg/closed-letter-svg.png'
import phoneIconSvg from '../../assets/svg/phone-icon-svg.png'



export default function OTPLoginAuth({ open, setOpen }) {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleClose = () => { 
    setOpen(false);
    setSelectedOption(null);
  }
  const email= localStorage.getItem("email")
  const user_type = "Innoceans"

  const handleSentOTPViaEmail = () => {
    setSelectedOption('email');
}

const handleSentOTPViaSMS = () => {   
    setSelectedOption('sms'); 
}

const handleSendLink = async() => {
  if (selectedOption === 'email') {
    const response = await dispatch(authOtpViaEmailApi({ type: "email", user_type, email: email }));
    if (!response?.payload?.error) {
      setEmailVerifyModal(true);
      setOpen(false);
      //setSelectedOption(null);
    }else{
      showToast(response?.payload?.message, 2)
    }
} else if (selectedOption === 'sms') {
  const responseSMS =  await dispatch(authOtpViaSMSApi());
  if (!responseSMS?.payload?.error) {
    setPhoneVerifyModal(true);
    setOpen(false);setSelectedOption(null);
  }else{
    showToast(responseSMS?.payload?.message, 2)
  }
}

}

  

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`} >
         <Stack spacing={2} p={3} position={"relative"}>
            <Stack 
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems="center">
              <Typography
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >
                OTP Authentication
              </Typography>
              <Typography className={ModalCss?.subtitle} fontSize={16}>
                Where do you want to receive OTP?
              </Typography>
            </Stack>
            <Card
              sx={{
                p: 0,
                marginTop: "30px !important",
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}
            >
              <CardActionArea
                // sx={{ px: 2, py: 3 }}
                // onClick={handleSentOTPViaEmail}
                style={{ padding: "16px", backgroundColor: selectedOption === 'email' ? '#A8A8A8' : '#fff' }} onClick={handleSentOTPViaEmail}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                    <img src={closeLetterIconSvg} alt="emailIcon" />
                  </Avatar>
                  <Box>
                    <Typography fontWeight={600} fontSize={18}  sx={{textTransform: "none"}}>
                      OTP via Email
                    </Typography>
                    <Typography fontSize={15}>
                      An OTP will be sent to your registered email address 
                    </Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card>
            {/* <Card
              sx={{
                p: 0,
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}
            >
              <CardActionArea
                // sx={{ px: 2, py: 3 }}
                // onClick={handleSentOTPViaSMS}
                style={{ padding: "16px", backgroundColor: selectedOption === 'sms' ? '#A8A8A8' : '#fff' }} onClick={handleSentOTPViaSMS}
              >
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                   <img src={phoneIconSvg} alt="emailIcon" />
                  </Avatar>
                  <Box>
                    <Typography fontWeight={600} fontSize={18}>
                      OTP Via SMS
                    </Typography>
                    <Typography fontSize={15}>
                      An OTP will be sent to your registered mobile number
                    </Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card> */}
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={handleSendLink}
                // onClick={handleClose}
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "16px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                  textTransform: "none"
                }}
              >
                Send OTP
              </Button>
            </Box>
            <Stack justifyContent="center" direction="row" alignItems="center" fontSize={'18px'} marginTop={"30px"}>
                {/* <Typography
                textAlign={"center"}
                fontSize={'inherit'}
                
                color={"#000000"}
                >
                Didn’t receive an OTP?
                </Typography> */}
                <Button 
                    marginLeft="5px !important"
                    onClick={handleSentOTPViaEmail}
                    sx={{fontSize: "16px", fontWeight: "600"}}
                >
                   
                </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>

            <OTPEmailAuth open={emailVerifyModal} setOpen={setEmailVerifyModal} handleSentOTPViaEmail={handleSentOTPViaEmail} openPreviousModal={setOpen} handleSendLink={handleSendLink} handleCloseP={handleClose}/>
            <OTPSMSAuth open={phoneVerifyModal} setOpen={setPhoneVerifyModal} openPreviousModal={setOpen} />
        </>
    )
}
