import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";

export const postGFCApi = createAsyncThunk(
    'layout/postGFC',
    async (data, { rejectWithValue, getState }) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/layout/add_gfc/`, data,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const postGFCSlice = createSlice({
    name: 'postGFC',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(postGFCApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postGFCApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(postGFCApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const postGFCReducer = postGFCSlice.reducer