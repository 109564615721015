import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { convertToFormData } from "../convertFormData";
import { useDispatch, useSelector } from "react-redux";
import { getSchematicLayoutByClusterIdApi } from "../../store/home/getSchematicLayout";
import { cadUploadSchema, pdfUploadSchema, schematicLayoutSchema } from "../validationSchemas";
import { postSchematicLayoutApi } from "../../store/home/postSchematicLayout";
import { showToast } from "../../toast/toast";
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'

import { handleDownload } from "../reuseable/downloadFile";
import Loader from "../reuseable/Loader";


export default function SchematicLayout({ formID }) {
    const dispatch = useDispatch();
    const {result : schematicLayoutData, loading : schematicLayoutLoading} = useSelector((state) => state?.schematicLayoutByClusterReducer);
    const {result : revisedSchematicLayoutData, loading : revisedShematicLayoutLoading} = useSelector((state) => state?.revisedLayoutViewReducer);
    const [loading, setLoading] = useState(false);
    
    const [data, setData] = useState([]);
    useEffect(() => {
        const isRevisedViewClick = localStorage.getItem("isRevisedViewClick");
        setData(isRevisedViewClick === '1' ? revisedSchematicLayoutData : schematicLayoutData);
      }, [revisedSchematicLayoutData, schematicLayoutData]);

      
    const [addSchematicLayout ,  setAddSchematicLayout] = useState();
    const [pdffileState, setPdfFileState] = useState([]);
    const [cadfileState, setCadFileState] = useState([]);
    const pdfRef = useRef(null);
    const cadRef = useRef(null);
    
    const designEstimateInputRef = useRef(null);
    const moreAttachmentInputRef = useRef(null);
    const [designEstimateFileState, setDesignEstimateFileState] = useState([]);
    const [moreAttachmentFileState, setMoreAttachmentFileState] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSentPreviousOne, setIsSentPreviousOne] = useState(false);

    const handleChange = (event) => {
        const value = event.target.value;
        setAddSchematicLayout(prevState => ({
          ...prevState,
          [event.target.name]: value
        }));
      };
    const handleCheckboxChange = (event) => {
        setAddSchematicLayout(prevState => ({
          ...prevState,
          'previous_estimate': event.target.checked,
          'designEstimateFile': [],
          
        }))
        setDesignEstimateFileState([]);
        setErrors(prevErrors => ({ ...prevErrors, previous_estimate: "" }));
        setIsSentPreviousOne(event.target.checked);
    };

    const handlePdfUpload = () => {
        pdfRef.current.value = null;  // Clear the file input
        pdfRef.current.click();
    };
    
    const handleCadUpload = () => {
        cadRef.current.value = null;  // Clear the file input
        cadRef.current.click();
    };

    const handlePdfFileUpload = (event) => {
        const files = Array.from(event.target.files);
        const pdfFiles = files.filter(file => file.name.toLowerCase().endsWith('.pdf'));
        if (pdfFiles.length === files.length) {
            setErrors({ ...errors, pdfFile: "" });
            setPdfFileState(prevState => [...prevState, ...pdfFiles]);
            setAddSchematicLayout(prevState => ({
                ...prevState,
                pdfFile: pdfFiles
            }))
        } else {
            setErrors(prevErrors => ({ ...prevErrors, pdfFile: "Only PDF files are allowed" }));
        }
    };

    const handleRemoveFile = (index, type) => {
        let updatedFiles;
    
        switch (type) {
            case "pdfFile":
                setPdfFileState(prevState => prevState.filter((_, i) => i !== index));
                setAddSchematicLayout(prevState => ({
                    ...prevState,
                    pdfFile: prevState.pdfFile.filter((_, i) => i !== index)
                }))

                break;
            case "cadFile":
                setCadFileState(prevState => prevState.filter((_, i) => i !== index));
                setAddSchematicLayout(prevState => ({
                    ...prevState,
                    cadFile: prevState.cadFile.filter((_, i) => i !== index)
                }))
                break;
            case "designEstimateFile":
                setDesignEstimateFileState(prevState => prevState.filter((_, i) => i !== index));
                setAddSchematicLayout(prevState => ({
                    ...prevState,
                    designEstimateFile: prevState.designEstimateFile.filter((_, i) => i !== index)
                }))
                break;
            case "moreAttachmentFile":
                setMoreAttachmentFileState(prevState => prevState.filter((_, i) => i !== index));
                setAddSchematicLayout(prevState => ({
                    ...prevState,
                    moreAttachmentFile: prevState.moreAttachmentFile.filter((_, i) => i !== index)
                }))
                break;
            default:
                console.error("Unknown file type");
        }
    };
    
    const handleCadFileUpload = (event) => {
        const files = Array.from(event.target.files);
        const dwgFiles = files.filter(file => file.name.toLowerCase().endsWith('.dwg'));

        if(dwgFiles.length ===0){
            setErrors(prevErrors => ({ ...prevErrors, cadFile: "Cad file is required" }));
            return
        }
        if (dwgFiles.length === files.length) {
            // Validate DWG files
            const isValid = cadUploadSchema.isValidSync({ cadFile: files });
            if (isValid) {
                setErrors(prevErrors => ({ ...prevErrors, cadFile: "" }));
                setCadFileState(prevState => [...prevState, ...dwgFiles]);
                setAddSchematicLayout(prevState => ({
                    ...prevState,
                    cadFile: dwgFiles
                }));
            } else {
                setErrors(prevErrors => ({ ...prevErrors, cadFile: "Cad file is required" }));
            }
        } else {
            setErrors(prevErrors => ({ ...prevErrors, cadFile: "Only DWG files are allowed" }));
        }
    };

    const handleDesignEstimateFileUpload = () => {

        designEstimateInputRef.current.value = null;  // Clear the file input
        designEstimateInputRef.current.click();
        setErrors(prevErrors => ({ ...prevErrors, designEstimateFile: "" }));

    };

    const handleMoreAttachmentFileUpload = () => {
        moreAttachmentInputRef.current.value = null;  // Clear the file input
        moreAttachmentInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const designFiles = Array.from(event.target.files);
        setDesignEstimateFileState((prevFile) => {
            if (prevFile.length + designFiles.length > 1) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    designEstimateFile: "Only one file can be uploaded."
                }));
                return prevFile; // Return previous state without adding files
            }
            setErrors(prevErrors => ({ ...prevErrors, designEstimateFile: "" }));
    
            // Update the layout state and other necessary states
            setAddSchematicLayout(prevState => ({
                ...prevState,
                designEstimateFile: designFiles
            }));
            setErrors(prevErrors => ({ ...prevErrors, previous_estimate: "" }));
            setIsSentPreviousOne(false);
    
            // Return the updated state with the new file
            return [...prevFile, ...designFiles];
        });
    };
    
    const handleFileChangeAttachement = (event) => {
        const attachFiles = Array.from(event.target.files);
        setMoreAttachmentFileState(prevFile => [...prevFile, ...attachFiles])
        setAddSchematicLayout(prevState => ({
            ...prevState,
            'moreAttachmentFile': attachFiles
        }))
        
    };

    const getSchematicLayout = async () => {
        const formD = {
            'form_id': formID
        }
        const dataToSubmit = convertToFormData(formD);
        try {
            await dispatch(getSchematicLayoutByClusterIdApi(dataToSubmit));
            //setData(response?.payload?.data);
        } catch (error) {
            showToast(error, 2)
        }
    }

    const addRequestAPI = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("form_id", formID);
        if(addSchematicLayout?.previous_estimate === true){
            formData.append("previous_estimate", true);
        }
        //formData.append("previous_estimate", addSchematicLayout?.previous_estimate)
        formData.append("comment", addSchematicLayout?.comment);
        if (pdffileState.length > 0) {
          pdffileState.forEach((file) => {
            formData.append("schematic_pdf", file);
    
          });
        }
        if (cadfileState.length > 0) {
          cadfileState.forEach((file) => {
            formData.append("schematic_cad", file);
          });
        }
        if (moreAttachmentFileState.length > 0) {
            moreAttachmentFileState.forEach((file) => {
            formData.append("more_attachments", file);
    
          });
        }
        if (designEstimateFileState.length > 0) {
            designEstimateFileState.forEach((file) => {
            formData.append("estimate", file);
    
          });
        }

        try{
            const addSchematicResponse = await dispatch(postSchematicLayoutApi(formData));
            setLoading(false)
            setIsRevisedRequest(false)
            localStorage.removeItem("isRevisedRequestClick");
            if (addSchematicResponse?.error?.message) {
                showToast(addSchematicResponse?.error?.message, 2);
                
                return;
            }else if (addSchematicResponse?.payload?.status === "error") {
                showToast(addSchematicResponse?.payload?.message, 2);
                
                return;
            }else{
                showToast(addSchematicResponse?.payload?.message, 1)
                dispatch(getSchematicLayout());
               
                
            }
            
        }catch(error){
        }       
        
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        schematicLayoutSchema.validate(addSchematicLayout, { abortEarly: false, context: { addSchematicLayout, isRevisedRequest: isRevisedRequest  }, })
            .then(() => {
                addRequestAPI();
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                if (validationErrors && Array.isArray(validationErrors.inner)) {
                    validationErrors.inner.forEach((error) => {
                      newErrors[error.path] = error.message;
                    });
                  }
                setErrors(newErrors);
            });
    };

    
    const resetForm = () => {
        
        setAddSchematicLayout({
            'comment': '',
            'previous_estimate': false,
            'more_attachments': '',
            'pdfFile': [],
            'cadFile': [],
            'designEstimateFile': [],
        })
        setPdfFileState([]);
        setCadFileState([]);
        setDesignEstimateFileState([]);
        setMoreAttachmentFileState([]);
        setErrors({});
    }

    const [ isRevisedRequest, setIsRevisedRequest ] = useState(false);
    const handleReset = () => {
        resetForm();  
        setIsRevisedRequest(true)
    }
    useEffect(() => {
        if(formID) {
            getSchematicLayout();
        }
    }, [formID]);

    if (schematicLayoutLoading) {
        return <Loader/>;
    }
    
    return (
        <>
            
            {!schematicLayoutLoading && (data.length===0 || isRevisedRequest )  && (
                
                    <form
                        onSubmit={handleSubmit}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <Grid container spacing={2} className="m-auto w-100" sx={{ background: "#F9FCFF", padding: "28px 26px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: "0px !important" }}>
                                    <Typography variant="h6" className="fs-17 ">Upload Schematic Layout</Typography>
                                    <Box className="d-flex flex-wrap" sx={{ gap: "24px" }}>
                                        <Box sx={{ maxWidth: "213px",width:"100%" }}>

                                            <Box className="upload-box text-center">
                                                <Box className="m-auto">
                                                    <input
                                                        type="file"
                                                        ref={pdfRef}
                                                        style={{ display: "none" }}
                                                        onChange={handlePdfFileUpload}
                                                        name="pdfFile"
                                                        accept="application/pdf"
                                                        multiple
                                                    />
                                                    
                                                    <Button onClick={handlePdfUpload}>
                                                        <FileUploadOutlinedIcon sx={{fontSize:"40px"}} ></FileUploadOutlinedIcon>
                                                    </Button>
                                                    
                                                    <Typography variant="h6" className="fs-14 ">Upload Design Plan</Typography>
                                                    
                                                    <span className="fs-12 ">(pdf File)</span>
                                                    {/* <span className="fs-12 ">(cad File)</span> */}

                                                <FormHelperText error sx={{textAlign: "center"}}>
                                                    {errors?.pdfFile}
                                                </FormHelperText>
                                                </Box>
                                                
                                            </Box>
                                            <Box sx={{height:"fit-content"}}>
                                            {pdffileState.length > 0 && pdffileState.map((file, index) => (
                                                <Box key={`pdf-${index}`} className="m-auto p-relative">
                                                    <Tooltip title={file.name} placement="top" className={`uploadedfilename`}>
                                                        <img width={40} height={40} src={pdf} className="my-auto" alt="pdfIcon" />
                                                        <Typography>{file.name}</Typography>
                                                        <CancelIcon onClick={() => handleRemoveFile(index, "pdfFile")} />
                                                    </Tooltip>
                                                </Box>
                                            ))}
                                            </Box>
                                        </Box>
                                        <Box sx={{ maxWidth: "213px",width:"100%" }}>

                                            <Box className="upload-box text-center">
                                                <Box className="m-auto">
                                                <input
                                                    type="file"
                                                    ref={cadRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleCadFileUpload}
                                                    name="cadFile"
                                                    multiple
                                                    accept=".dwg"
                                                />
                                                <Button onClick={handleCadUpload}>
                                                    <FileUploadOutlinedIcon sx={{fontSize:"40px"}} ></FileUploadOutlinedIcon>

                                                </Button>
                                                <Typography variant="h6" className="fs-14 ">Upload Design Plan</Typography>

                                                <span className="fs-12 ">(cad File)</span>

                                                <FormHelperText error  sx={{textAlign: "center"}}>
                                                    {errors?.cadFile}
                                                </FormHelperText>
                                                </Box>

                                            </Box>
                                            <Box sx={{height:"fit-content"}}>
                                            {cadfileState.length > 0 && cadfileState.map((file, index) => (
                                                <Box key={`cad-${index}`} className="m-auto p-relative">
                                                    <Tooltip title={file.name} placement="top" className={`uploadedfilename`}>
                                                        <img width={40} height={40} src={cad} className="my-auto" alt="cadIcon" />
                                                        <Typography>{file.name}</Typography>
                                                        <CancelIcon onClick={() => handleRemoveFile(index, "cadFile")} />
                                                    </Tooltip>
                                                </Box>
                                            ))}
                                            </Box>
                                        </Box>
                                        
                                    </Box>

                                    

                                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment (if Any)</Typography>
                                    
                                        <TextareaAutosize 
                                        error={Boolean(errors.comment)}
                                        helperText={errors.comment}
                                        value={addSchematicLayout?.comment}
                                        onChange={handleChange}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="comment"                                        
                                        style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} 
                                        className="bg-white" 
                                        minRows={6} />
                                        <Box className="w-100" sx={{ marginTop: '40px' }}>
                                            <Box className="d-flex" sx={{ gap: '18px' }}>
                                                <Typography variant="h6" className="fs-17 my-auto">Attach Design Estimates</Typography>
                                                <Button
                                                    onClick={handleDesignEstimateFileUpload}
                                                    className="fs-13"
                                                    sx={{ background: '#E2F1FF', borderRadius: '120px', lineHeight: 'normal', minWidth: '111px' }}
                                                >
                                                    <FileUploadOutlinedIcon />Upload
                                                </Button>
                                         
                                                <input
                                                    type="file"
                                                    ref={designEstimateInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    name="designEstimateFile"
                                                    accept=".pdf"
                                                />
                                                <FormHelperText error>
                                                    {errors?.designEstimateFile}
                                                </FormHelperText>
                                            </Box>
                                            <Box sx={{height:"fit-content"}}>
                                                {designEstimateFileState.length > 0 ? (
                                                    designEstimateFileState.map((file, index) => (
                                                        <Tooltip title={file.name} placement="top"  className={` uploadedfilename`} >
                                                           <Typography key={index} >
                                                           {file.name}
                                                           </Typography>
                                                           <CancelIcon onClick={() => handleRemoveFile(index, "designEstimateFile")} />
                                                       </Tooltip>
                                                    ))
                                                ) : ""}
                                            </Box>
                                            {schematicLayoutData.design_estimate && addSchematicLayout?.designEstimateFile && (
                                                <>
                                                <FormGroup>
                                                <FormControlLabel
                                                    className="checkbox-with-label fs-14"
                                                    control={
                                                        <Checkbox
                                                            checked={isSentPreviousOne}
                                                            onChange={handleCheckboxChange}
                                                            name="previous_estimate"
                                                        />
                                                    }
                                                    label="Send previous one"
                                                />
                                                <FormHelperText error>
                                                    {errors?.previous_estimate}
                                                </FormHelperText>
                                            </FormGroup>
                                                </>
                                            )
                                            }
                                            
                                        </Box>
                                        <Box className="w-100">
                                            <Button className="btn-more" sx={{ marginTop: '16px' }} onClick={handleMoreAttachmentFileUpload}>
                                            <AttachFileOutlinedIcon fontSize="small" sx={{ transform: 'rotate(15deg)' }}/>
                                                More Attachments
                                            </Button>
                                            <input
                                                type="file"
                                                ref={moreAttachmentInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChangeAttachement}
                                                name="more_attachements"
                                                multiple
                                            />

                                            <Box sx={{height:"fit-content"}}>
                                                {moreAttachmentFileState.length > 0 ? (
                                                    moreAttachmentFileState.map((file, index) => (
                                                        <Tooltip title={file.name} placement="top"  className={` uploadedfilename`} >
                                                           <Typography key={index} >
                                                           {file.name}
                                                           </Typography>
                                                           <CancelIcon onClick={() => handleRemoveFile(index, "moreAttachmentFile")} />
                                                       </Tooltip>
                                                    ))
                                                ) : ""}
                                            </Box>
                                        </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="m-auto w-100" sx={{ padding: "28px 26px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: "0px !important", gap: "22px" }}>
                                    <Button 
                                    className="black-btn" 
                                    type="submit"
                                    disabled={loading}
                                    >
                                    {loading ? "Loading..." : "Send To KIA"}
                                        
                                    </Button>
                                    {!loading  && (
                                        <Button className="link-btn" sx={{ marginLeft: "22px" }} onClick={() =>resetForm()}>
                                            Reset
                                        </Button>
                                    )}
                                    
                                </Grid>
                            </Grid>
                     </Grid>
                </form>                    
            )}
            {!schematicLayoutLoading && (data?.id>0 && isRevisedRequest===false) && (
                <Box className="h-100 d-flex flex-column justify-content-between">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Typography variant="h6" className="fs-17  fw-400">Schematic Layout</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{gap:"24px"}}>
                                <Box sx={{width:"213px"}}>
                                    {data?.schematic_pdf?.map((schematicLayoutData) => (
                                        <>
                                            <Box className="text-center">
                                                <Box className="w-100 p-relative">
                                                    <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                        <img width={40} height={40} src={pdf} className="my-auto" alt="pdficon"/>
                                                        <Typography>
                                                            {'PDF File'}
                                                        </Typography>
                                                        <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                        <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </>
                                    ))}
                                </Box>
                                <Box sx={{width:"213px"}}>
                                    {data?.schematic_cad?.map((schematicLayoutData) => (
                                        <>
                                            <Box className="text-center">
                                                <Box className="w-100 p-relative">
                                                    <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                        <img width={40} height={40} src={cad} className="my-auto" alt="cadIcon" />
                                                        <Typography>
                                                            {'CAD File'}
                                                        </Typography>
                                                        <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                        <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </>
                                    ))}
                                </Box>

                                <Box className="w-100" sx={{ marginTop: '40px' }}>
                                    <Box className="d-flex" sx={{ gap: '18px' }}>
                                        <Typography variant="h6" className="fs-17 my-auto">Attached Design Estimates</Typography>
                                    </Box>
                                    <Box sx={{height:"fit-content"}}>
                                        <Tooltip title={data?.design_estimate?.estimate} placement="top"  className={` uploadedfilename`} >
                                            <Typography>
                                            {'Design Estimate'}
                                            </Typography>
                                            <DownloadForOfflineRoundedIcon onClick={() => handleDownload(new Date()+'schematic_designEstimate',data?.design_estimate?.estimate)}/>
                                            <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data?.design_estimate?.estimate}`)} />
                                        </Tooltip>
                                    </Box>
                                    
                                </Box>
                                <Box className="w-100" sx={{ marginTop: '5px' }}>
                                    <Box className="d-flex" sx={{ gap: '18px' }}>
                                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment </Typography>
                                    </Box>
                                    <Box sx={{height:"fit-content"}}>
                                    <TextareaAutosize 
                                        disabled
                                        value={data?.comment}
                                        onChange={handleChange}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="comment"                                        
                                        style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} 
                                        className="bg-white" 
                                        minRows={6} />
                                    </Box>
                                </Box>

                                <Box className="w-100" sx={{ marginTop: '5px' }}>
                                    <Box className="d-flex" sx={{ gap: '18px' }}>
                                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>More Attachements </Typography>
                                    </Box>
                                    <Box sx={{
                            width: "100%",
                            maxHeight: "200px",
                            overflowY: "auto",
                            marginBottom: "20px",
                            }}>
                            <Grid container spacing={{ xs: 1, lg: 2 }}>
                                    {data?.schematic_files && data.schematic_files.length > 0 ? (
                                            data.schematic_files.map((schematicLayoutData) => (
                                            <Grid item xs={6} sm={"auto"} key={schematicLayoutData.files}>
                                                <Box className="bg-white text-center" sx={{ height: "auto !important" }}>
                                                <Box className="m-auto p-relative">
                                                    <Tooltip
                                                    title={schematicLayoutData.files}
                                                    placement="top"
                                                    className="uploadedfilename justify-content-between gap-3"
                                                    sx={{ width: "100% !important", maxWidth: "190px !important" }}
                                                    >
                                                    <Typography sx={{ width: "auto !important" }}>
                                                        {"Attachment"}
                                                    </Typography>
                                                    <Box className="d-flex flex-nowrap gap-5 ml-auto">
                                                        <DownloadForOfflineRoundedIcon
                                                        className="my-auto"
                                                        onClick={() =>
                                                            handleDownload(new Date() + "schematic_cad", schematicLayoutData.files)
                                                        }
                                                        />
                                                        <FullscreenIcon
                                                        className="my-auto"
                                                        onClick={() =>
                                                            window.open(
                                                            `${process.env.REACT_APP_BASE_URL}${schematicLayoutData?.files}`
                                                            )
                                                        }
                                                        />
                                                    </Box>
                                                    </Tooltip>
                                                </Box>
                                                </Box>
                                            </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                            <Typography>No attachment available</Typography>
                                            </Grid>
                                        )}
                                        </Grid>
                                    </Box>
                                    
                                </Box>
                                    
                                        
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{gap:"23px"}}>
                            <Button className="white-btn" onClick={() => handleReset()}>Reset</Button>
                        </Grid>
                    </Grid>

            </Box>
        )}
        </>
    )
};