export const handleDownload = (fileName , url) => {
    const link = document.createElement('a');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fileUrl = baseUrl+`${url}`;
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// export const handleDownloadMultipleFile = (fileName, fileObjects) => {
//     const baseUrl = process.env.REACT_APP_BASE_URL;
    
//     fileObjects.forEach((fileObj, index) => {
//         const link = document.createElement('a');
//         const fileUrl = baseUrl + `${fileObj.files}`;  // Access the path key from each object
//         link.href = fileUrl;
        
//         const newFileName = `${fileName}_${index + 1}`;
//         link.download = newFileName;
//         link.target = '_blank';
//         document.body.appendChild(link);
        
//         // Trigger the file download
//         link.click();
//         document.body.removeChild(link);
//     });
// };

export const handleDownloadMultipleFile = (fileName, fileObjects) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    fileObjects.forEach((fileObj, index) => {
        const fileUrl = baseUrl + `${fileObj.files}`;  // Access the path key from each object
        const newFileName = `${fileName}_${index + 1}`;

        // Fetch the file to force download as a Blob
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = newFileName;  // Set the download attribute

                document.body.appendChild(link);

                // Trigger the file download
                link.click();

                // Clean up the URL and remove the link
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    });
};